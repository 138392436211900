import { Link, Text } from '@shopify/polaris'
import { Trans } from 'react-i18next'
import i18next from 'i18next'

const Footer = () => {
  return (
    <div
      style={{
        width: '100%',
        maxWidth: '680px',
        margin: '0 auto',
        padding: '2rem 0 1rem 0',
      }}
    >
      <Text as="p" alignment="center">
        <Trans t={i18next.t} i18nKey="footerMessageLine1">
          .
          <Link target="_blank" url="https://www.shopside.com.au">
            .
          </Link>
        </Trans>
      </Text>
    </div>
  )
}

export { Footer }
