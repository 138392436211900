import { Card, SkeletonBodyText, InlineStack, Box, SkeletonDisplayText, BlockStack } from '@shopify/polaris'

const SkeletonChart = () => {
  return (
    <Card>
      <BlockStack gap="400">
        <InlineStack gap="400">
          <Box>
            <Box width="150px" paddingBlockEnd="200">
              <SkeletonBodyText lines={1} />
            </Box>
            <SkeletonDisplayText size="medium" />
          </Box>
          <Box>
            <Box width="150px" paddingBlockEnd="200">
              <SkeletonBodyText lines={1} />
            </Box>
            <SkeletonDisplayText size="medium" />
          </Box>
          <Box>
            <Box width="150px" paddingBlockEnd="200">
              <SkeletonBodyText lines={1} />
            </Box>
            <SkeletonDisplayText size="medium" />
          </Box>
        </InlineStack>
        <Box minHeight="240px" background="bg-fill-tertiary" />
      </BlockStack>
    </Card>
  )
}

export { SkeletonChart }
