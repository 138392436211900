import { createRootRouteWithContext, Outlet, ScrollRestoration, Link } from '@tanstack/react-router'
import { TanStackRouterDevtools } from '@tanstack/router-devtools'
import i18next from 'i18next'
import type { QueryClient } from '@tanstack/react-query'
import { useBeacon } from '@/hooks/useBeacon'
import { NavMenu } from '@shopify/app-bridge-react'
import { Footer } from '@/components/shared/Footer'

export const Route = createRootRouteWithContext<{
  queryClient: QueryClient
}>()({
  component: Component,
})

function Component() {
  const { hasBeacon, registerBeacon, populateBeacon } = useBeacon()

  if (!hasBeacon) {
    registerBeacon()
  }

  populateBeacon()

  return (
    <>
      <NavMenu>
        <Link to="/" rel="home">
          {i18next.t('navDashboard')}
        </Link>
        <Link to="/preorders">{i18next.t('navPreorders')}</Link>
        <Link to="/backorders">{i18next.t('navBackorders')}</Link>
        <Link to="/back-in-stock">{i18next.t('navBackInStock')}</Link>
        <Link to="/display">{i18next.t('navDisplay')}</Link>
        <Link to="/settings">{i18next.t('navSettings')}</Link>
        <Link to="/plans">{i18next.t('navAppPlans')}</Link>
        <Link to="/emails">{i18next.t('navEmailTemplates')}</Link>
        <Link to="/localization">{i18next.t('navLocalization')}</Link>
      </NavMenu>
      <ScrollRestoration />
      <Outlet />
      <Footer />
      {import.meta.env.DEV && <TanStackRouterDevtools />}
    </>
  )
}
