import { Card, SkeletonBodyText, InlineGrid, SkeletonTabs, Divider, Box, Grid } from '@shopify/polaris'
import { useMemo } from 'react'
import { v4 as uuidv4 } from 'uuid'

const SkeletonTable = ({ rows = 10, narrow = false }: { rows?: number; narrow?: boolean }) => {
  // Memo the row keys to prevent re-rendering
  const rowKeys = useMemo(() => Array.from({ length: rows }, () => uuidv4()), [rows])

  return (
    <Card padding="0">
      <Filters />
      {rowKeys.map((key) => (
        <div key={key}>
          <Divider />
          {narrow ? <NarrowRow /> : <Row />}
        </div>
      ))}
    </Card>
  )
}

const Filters = () => {
  return <SkeletonTabs />
}

const Row = () => (
  <Box padding="400">
    <Grid columns={{ sm: 3 }}>
      <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
        <SkeletonBodyText lines={1} />
      </Grid.Cell>
      <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
        <InlineGrid columns={2} gap="400">
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
        </InlineGrid>
      </Grid.Cell>
      <Grid.Cell columnSpan={{ xs: 6, sm: 3, md: 3, lg: 4, xl: 4 }}>
        <InlineGrid columns={3} gap="400">
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
          <SkeletonBodyText lines={1} />
        </InlineGrid>
      </Grid.Cell>
    </Grid>
  </Box>
)

const NarrowRow = () => (
  <Box padding="400">
    <InlineGrid columns={3} gap="400">
      <SkeletonBodyText lines={1} />
      <SkeletonBodyText lines={1} />
      <SkeletonBodyText lines={1} />
    </InlineGrid>
  </Box>
)

export { SkeletonTable }
