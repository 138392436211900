import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import { APP_NAME } from '../common/constants'

i18n
  // Add React bindings as a plugin.
  .use(initReactI18next)
  // Initialize the i18next instance.
  .init({
    lng: shopify?.config?.locale ?? 'en-US',
    fallbackLng: 'en',
    debug: true,

    // React does this escaping itself, so we turn
    // it off in i18next.
    interpolation: {
      escapeValue: false,
    },

    resources: {
      // English
      en: {
        translation: {
          // Global
          appName: APP_NAME,
          datetimeAt: 'at',
          genericErrorTitle: 'Hmm, there was an error...',
          genericErrorToast: 'An error occurred',
          genericUnsavedChanges: 'Unsaved changes',
          genericTotal: 'Total',
          genericTotals: 'Totals',
          genericNa: 'N/A',
          genericDismiss: 'Dismiss',
          genericHide: 'Hide',
          genericShow: 'Show',
          genericEdit: 'Edit',
          genericUndo: 'Undo',
          genericCancel: 'Cancel',
          genericApply: 'Apply',
          genericConfigure: 'Configure',
          genericChange: 'Change',
          genericBrowse: 'Browse',
          genericLoading: 'Loading...',
          genericDashboard: 'Dashboard',
          genericUnlimited: 'Unlimited',
          genericItemsCount_one: '{{count}} item',
          genericItemsCount_other: '{{count}} items',
          genericPercentage: '{{percentage}}%',
          genericUpgradePlanBanner: '<1>Upgrade your plan</1> to access this feature.',
          genericContactUsBanner: '<1>Contact us</1> to activate this feature.',
          genericContactSupport: 'Contact support',
          genericBackgroundColor: 'Background color',
          genericTextColor: 'Text color',
          genericSelectColor: 'Select color',
          genericSelectDate: 'Select a date',
          genericSelectTime: 'Select a time',
          genericMarkAsDone: 'Mark as done',
          genericDone: 'Done',
          genericLearnMore: 'Learn more',
          genericReadMore: 'Read more',
          genericWatchVideo: 'Watch video',
          genericViewDetails: 'View details',
          genericViewProduct: 'View product',
          genericEditProduct: 'Edit product',
          genericMoreActions: 'More actions',
          genericRecommendedSuffix: '(recommended)',
          footerMessageLine1: 'Proudly made and supported by <1>Shopside</1> in Brisbane, Australia',
          footerMessageLine2:
            'You can visit the <1>Help Center</1> for access to our documentation, or use our in-app support to send us a message. We always try to respond within 24 hours.',

          // Generic time
          genericUtc: 'UTC',
          genericGmt: 'GMT',
          genericLessThanAMinute: 'less than a minute',
          genericSecond_one: '{{count}} second',
          genericSecond_other: '{{count}} seconds',
          genericMinute_one: '{{count}} minute',
          genericMinute_other: '{{count}} minutes',
          genericHour_one: '{{count}} hour',
          genericHour_other: '{{count}} hours',
          genericDay_one: '{{count}} day',
          genericDay_other: '{{count}} days',

          // Generic x
          generic1x: '1x',
          generic2x: '2x',
          generic5x: '5x',
          generic10x: '10x',
          generic20x: '20x',

          // Number formats
          intlCurrency: '{{val, currency(USD)}}',
          intlNumber: '{{val, number}}',

          // Navigation
          navDashboard: 'Dashboard',
          navPreorders: 'Pre-orders',
          navBackorders: 'Back-orders',
          navBackInStock: 'Back in stock',
          navDisplay: 'Display',
          navSettings: 'Settings',
          navEmailTemplates: 'Email templates',
          navAppPlans: 'Billing',
          navLocalization: 'Localization',

          // Error page
          errorPageTitle: 'An error occurred',
          errorPageDescription: `Try refreshing the page, clicking ${APP_NAME} in the navigation menu to return to the dashboard, or clicking the help icon in the bottom right corner to contact support.`,
          errorLogTitle: 'Error log',

          notFoundTitle: `There's no page at this address`,
          notFoundDescription: `Check the URL and try again, or click '${APP_NAME}' in the navigation menu.`,

          // Auth Guard
          authGuardAuthenticatingTitle: 'Authenticating your subscription',
          authGuardFetchingTitle: 'Fetching your settings',
          authGuardErrorTitle: 'Error fetching settings',
          authGuardInstallingTitle: `Setting up ${APP_NAME}`,
          authGuardOhNoTitle: 'An error occurred',
          authGuardOhNoLine1: 'This is an embedded app that needs to be loaded from within Shopify. Please try accessing it again via your Shopify admin.',
          authGuardOhNoLine2: 'If the issue persists, please get in contact with our support team.',
          authGuardBannedTitle: 'An error occurred',
          authGuardBannedLine1: `Your access was denied while trying to load ${APP_NAME}. Try refreshing the page to try again.`,
          authGuardBannedLine2: 'If the issue persists, please get in contact with our support team.',
          authGuardRedirectingTitle: `Redirecting to ${APP_NAME} installation`,
          authGuardRedirectingLine1: `Please contact us if you have any questions about ${APP_NAME}.`,
          authGuardLoadingLine1: `Thank you for using ${APP_NAME}!`,
          authGuardLoadingLine2: 'Please contact us if you have any questions or get stuck during setup.',
          authGuardLoadingLine3:
            'You can visit the <1>Help Center</1> for access to our documentation, or use our in-app support to send us a message. We always try to respond within 24 hours.',

          // Campaign statuses
          campaignStatusDraft: 'Draft',
          campaignStatusActive: 'Active',
          campaignStatusPaused: 'Stopped',
          campaignStatusScheduled: 'Scheduled',
          campaignStatusProcessing: 'Processing',
          campaignStatusArchived: 'Archived',

          // Dashboard
          dashTitle: `Welcome to ${APP_NAME}!`,
          dashSubtitle: `Here's a summary of ${APP_NAME}'s performance.`,
          dashCta: 'Create campaign',

          embedBlockBannerTitle: `Activate the ${APP_NAME} app embed`,
          embedBlockBannerAction: 'Activate app embed',
          embedBlockBannerSecondaryAction: 'Mark as done',
          embedBlockBannerSecondaryActionLoading: 'Checking...',
          embedBlockBannerDescription: `For ${APP_NAME} to appear on your storefront, the app embed must be activated and saved on your theme.`,
          embedBlockBannerSuccessDescription: `The ${APP_NAME} app embed has been activated and will now appear for relevant products on your storefront.`,

          legacyThemeBannerTitle: 'Vintage theme detected',
          legacyThemeBannerAction: 'Contact support',
          legacyThemeBannerDescription: `Some functionality might not be available on your current theme. We recommend using an OS2.0 theme to ensure full compatibility. If you need help getting ${APP_NAME} to work with your current theme, please contact support.`,

          // Onboarding
          onboardingTitle: 'Setup guide',
          onboardingDescription: 'Use this personalized guide to get your store up and running.',
          onboardingProgressDescription: '{{complete}} of {{total}} completed',

          onboardingMarkAsDone: 'Mark as done',
          onboardingMarkAsNotDone: 'Mark as not done',

          onboardingDismissGuide: 'Dismiss guide',
          onboardingHiddenToast: 'Setup guide dismissed',

          // Things to do
          thingsToDoTitle: 'Things to do next',
          thingsToDoFooter: 'All caught up',

          thingsToDoReviewRulesTitle: 'Review requirements and restrictions for pre-orders',
          thingsToDoReviewRulesDescription:
            'Before offering pre-orders on your store, you must understand the legal requirements for pre-orders in your area and where your customers are located.',
          thingsToDoReviewRulesPrimaryCta: 'View requirements',

          thingsToDoHelpCenterTitle: 'Get answers to frequently asked questions',
          thingsToDoHelpCenterDescription: `Learn how to get started with ${APP_NAME} and deepen your understanding of pre-orders and back in stock emails. If you have any questions not answered in our help center, please contact support.`,
          thingsToDoHelpCenterPrimaryCta: 'Visit help center',
          thingsToDoHelpCenterSecondaryCta: 'Contact support',

          thingsToDoCustomizeDisplayTitle: `Customize how ${APP_NAME} displays on your storefront`,
          thingsToDoCustomizeDisplayDescription: `Update the styling and display options for ${APP_NAME} to match your theme and branding. Styles will be inherited from your theme automatically, but additional changes can be made via display settings.`,
          thingsToDoCustomizeDisplayPrimaryCta: 'Customize display',

          thingsToDoActivateBackordersTitle: 'Activate back-orders to avoid lost sales',
          thingsToDoActivateBackordersDescription:
            'Continue selling sold out products as back-orders. Select specific products, or your entire catalog and we can update your inventory settings to continue selling when out of stock.',
          thingsToDoActivateBackordersPrimaryCta: 'Configure back-orders',

          thingsToDoActivateBackInStockTitle: 'Automate back in stock notifications',
          thingsToDoActivateBackInStockDescription: `Don't want to sell stock you don't have? No problem! Collect customer email addresses and we'll automatically let them know when you restock.`,
          thingsToDoActivateBackInStockPrimaryCta: 'Configure back in stock notifications',

          thingsToDoCreateCampaignTitle: 'Create your first pre-order campaign',
          thingsToDoCreateCampaignDescription:
            'Pre-orders let you gauge demand to avoid overstocking and negative cash flow. Schedule campaigns, add discounts, and offer flexible split-cart or deferred payments for your customers.',
          thingsToDoCreateCampaignPrimaryCta: 'Create campaign',

          thingsToDoLeaveReviewTitle: `How would you rate your experience using ${APP_NAME}?`,
          thingsToDoLeaveReviewTitleWithRevenue: `You have made {{revenue}} with ${APP_NAME}!`,
          thingsToDoLeaveReviewDescription: 'Posting a quick review of your experience helps other merchants decide which app to use on their store.',
          thingsToDoLeaveReviewDescriptionWithRevenue: `Congratulations on earning {{revenue}} with ${APP_NAME}! Consider writing a quick review of your experience. It helps other store owners decide which app to use on their store.`,
          thingsToDoLeaveReviewPrimaryCta: 'Rate in app store',

          thingsToDoLocalisationTitle: 'Localize labels and messages',
          thingsToDoLocalisationDescription: `Ensure labels and messaging used by ${APP_NAME} are consistent with your store's brand, language and locale. Update wording and set date formats via localization settings.`,
          thingsToDoLocalisationPrimaryCta: 'Customize localization',

          thingsToDoUpgradePlanTitle: 'Choose the right plan for your store',
          thingsToDoUpgradePlanDescription:
            'Increase your monthly limits and gain access to new features by selecting the best plan to suit your store and pre-sale needs. Custom plans are available via support.',
          thingsToDoUpgradePlanPrimaryCta: 'View plans',

          thingsToDoCancelExtensionTitle: 'Let customers manage their own orders',
          thingsToDoCancelExtensionDescription: `When offering pre-orders or back-orders, customers have the right to cancel or amend their order prior to fulfillment. Allow customers to manage this by activating the ${APP_NAME} checkout extension.`,
          thingsToDoCancelExtensionPrimaryCta: 'Activate extension',
          thingsToDoCancelExtensionSecondaryCta: 'Learn more',

          // Usage stats
          usageStatsBannerExceededTitle: 'Exceeded usage limit',
          usageStatsBannerExceededDescription: 'One or more services have been cut off. <1>Upgrade</1> your plan to restore access immediately.',
          usageStatsBannerGraceTitle: 'Exceeded usage limit',
          usageStatsBannerGraceDescription: 'You have 24 hours to <1>upgrade</1> your plan before exceeded services are cut off.',
          usageStatsBannerApproachingTitle: 'Approaching usage limit',
          usageStatsBannerApproachingDescription: 'Consider <1>upgrading</1> your plan to prevent any service interruptions.',
          usageStatsTitle: 'Monthly usage',
          usageStatsSubtitle: '{{from}} - {{to}}',
          usageStatsPlanTitle: 'Current plan',
          usageStatsPreordersTitle: 'Pre-orders',
          usageStatsEmailsTitle: 'Back in stock emails',
          usageStatsSmsTitle: 'SMS sent',
          usageStatsChangePlanCta: 'Change',

          // Key metrics
          keyMetricsTitle: 'Key metrics',
          keyMetricsSubtitle: `A high-level overview of ${APP_NAME}'s all time performance.`,
          keyMetricsPreorderRevenueTitle: 'Pre-order revenue',
          keyMetricsPreorderRevenueTooltip: `Total pre-order revenue generated by ${APP_NAME}.`,
          keyMetricsBackorderRevenueTitle: 'Back-order revenue',
          keyMetricsBackorderRevenueTooltip: `Total back-order revenue generated by ${APP_NAME}.`,
          keyMetricsBackInStockRevenueTitle: 'Back in stock revenue',
          keyMetricsBackInStockRevenueTooltip: `Total back in stock revenue generated by ${APP_NAME}.`,
          keyMetricsBackInStockTitle: 'Back in stock signups',
          keyMetricsBackInStockTooltip: `Total back in stock signups captured by ${APP_NAME}.`,

          // Recent orders
          recentOrdersTitle: 'Recent orders',
          recentOrdersSubtitle: 'Most recent orders across all pre-order and back-order campaigns',

          // Orders table
          ordersTableResourceName: 'order',
          ordersTableResourceNamePlural: 'orders',
          ordersTableEmptyTitle: 'No orders found',
          ordersTableEmptyDescription: 'Try changing your filters or checking back again later.',
          ordersTableEmptyProductsPopoverError: 'There was an error fetching line items.',
          ordersTablePopoverStoreLocation: 'Store location',
          ordersTablePopoverDeliveryMethod_LOCAL: 'Local delivery',
          ordersTablePopoverDeliveryMethod_NONE: 'No delivery',
          ordersTablePopoverDeliveryMethod_PICK_UP: 'Pick up',
          ordersTablePopoverDeliveryMethod_RETAIL: 'Retail',
          ordersTablePopoverDeliveryMethod_SHIPPING: 'Shipping',

          ordersTableTabAll: 'All',
          ordersTableTabUnfulfilled: 'Unfulfilled',
          ordersTableTabScheduled: 'Scheduled',
          ordersTableTabFulfilled: 'Fulfilled',
          ordersTableTabOnHold: 'On hold',
          ordersTableTabPartiallyPaid: 'Partially paid',
          ordersTableTabRefunded: 'Refunded',

          ordersTableFieldName: 'Order',
          ordersTableFieldItems: 'Items',
          ordersTableFieldCustomer: 'Customer',
          ordersTableFieldDate: 'Date',
          ordersTableFieldPreorderTotal: 'Pre-order total',
          ordersTableFieldBackorderTotal: 'Back-order total',
          ordersTableFieldPaymentStatus: 'Payment status',
          ordersTableFieldFulfillmentStatus: 'Fulfillment status',

          ordersTablePaymentStatusPaid: 'Paid',
          ordersTablePaymentStatusPartiallyPaid: 'Partially paid',
          ordersTablePaymentStatusPartiallyRefunded: 'Partially refunded',
          ordersTablePaymentStatusRefunded: 'Refunded',
          ordersTablePaymentStatusVoided: 'Voided',
          ordersTablePaymentStatusAuthorized: 'Authorized',
          ordersTablePaymentStatusExpired: 'Expired',
          ordersTablePaymentStatusPending: 'Payment pending',

          customerPopoverCta: 'View customer',

          ordersTableAutoPaymentStatusAwaitingDeferred: 'The remaining balance will be auto-captured on {{date}}.',
          ordersTableAutoPaymentStatusQueued: `${APP_NAME} is auto-capturing the remaining balance.`,
          ordersTableAutoPaymentStatusProcessing: `${APP_NAME} is auto-capturing the remaining balance.`,
          ordersTableAutoPaymentStatusError: `An error occurred while attempting to auto-capture the remaining balance. Please contact ${APP_NAME} support.`,
          ordersTableAutoPaymentStatusPaid: `${APP_NAME} auto-captured the remaining balance on {{date}}.`,

          ordersTableFulfillmentStatusFulfilled: 'Fulfilled',
          ordersTableFulfillmentStatusUnfulfilled: 'Unfulfilled',
          ordersTableFulfillmentStatusPartiallyFulfilled: 'Partially fulfilled',
          ordersTableFulfillmentStatusInProgress: 'In progress',
          ordersTableFulfillmentStatusOnHold: 'On hold',
          ordersTableFulfillmentStatusScheduled: 'Scheduled',

          // Back-orders
          backordersDashTitle: 'Back-orders',
          backordersDashTitleSubtitle: 'View and manage your back-orders.',
          backordersCtaPublish: 'Activate back-orders',
          backordersCtaUnpublish: 'Deactivate back-orders',
          backordersEditGroupCta: 'Configure',
          backordersEditDisplayCta: 'Display',
          backordersEditSettingsCta: 'Settings',

          // Backorder statuses
          backorderStatusActive: 'Active',
          backorderStatusInactive: 'Inactive',
          backorderStatusUpdating: 'Updating',
          backorderStatusError: 'Error',

          // Back-order banners
          backordersInventoryPolicyWarningTitle: 'Back-orders may not be available for your products',
          backordersInventoryPolicyWarningDescription: `Back-orders won't show for your products unless you set them to continue selling when out of stock.`,
          backordersInventoryPolicyWarningCta: 'Configure back-orders',

          backordersInventoryPolicyInlineWarning: `Back-orders won't show for your products unless you set them to continue selling when out of stock, or opt into ${APP_NAME} managing this.`,

          // Back-order metrics
          backorderMetricsRevenue: 'Back-order revenue ({{currency}})',
          backorderMetricsPreorders: 'Back-orders',

          // Activating progress/checklist
          backorderChecklistUpdatingTitle: 'Updating back-orders...',
          backorderChecklistUpdatedTitle: 'Back-orders have been updated',
          backorderChecklistProcessingDescription:
            'Please wait while we process your back-order products. This may take a few minutes. You can leave this page and come back if needed.',

          backordersChecklistErrorTitle: 'Error processing back-orders',
          backordersChecklistErrorDescription: 'Something went wrong while trying to update back-order products. Please try again or contact support.',

          backorderChecklistItemUpdating: 'Updating back-orders (approx 60 seconds)',
          backorderChecklistItemDiscoveringProducts_one: 'Scanning for selected products, found {{count}} matching product.',
          backorderChecklistItemDiscoveringProducts_other: 'Scanning for selected products, found {{count}} matching products.',
          backorderChecklistItemUploading: 'Uploading changes to Shopify (approx 5 seconds)',
          backorderChecklistItemUpdatingProducts_one: 'Updating {{count}} product (approx {{eta}})',
          backorderChecklistItemUpdatingProducts_other: 'Updating {{count}} products (approx {{eta}})',
          backorderChecklistItemUpdated: 'Back-orders are updated',

          // Back-order settings
          backorderSettingsTitle: 'Back-order settings',
          backorderSettingsSubtitle: `Customize your global ${APP_NAME} settings.`,

          backorderSubmissionErrorTitle: 'Backorder settings update failed',
          backorderSubmissionError: 'An error occurred while saving the backorder settings. Please try again or contact us for support.',

          backorderSelectionTypeTitle: 'Scope',
          backorderSelectionTypeDescription: 'Choose how to handle selected products.',
          backorderSelectionTypeCardTitle: 'Back-order scope',
          backorderSelectionTypeCardDescription: 'For which products should back-orders be activated?',
          backorderFieldSelectionTypeDisabledBanner: 'Deactivate back-orders to change the scope.',
          backorderFieldSelectionTypeInclude: 'Selected products only',
          backorderFieldSelectionTypeExclude: 'Entire catalog, excluding any products selected below',

          backorderProductsTitle: 'Products',
          backorderProductsExcludedDescription: 'Select the products to exclude for back-orders.',
          backorderProductsIncludedDescription: 'Select the products to include for back-orders.',
          backorderProductsExcludedCardTitle: 'Excluded products',
          backorderProductsIncludedCardTitle: 'Included products',
          backorderProductsExcludedCardDescription: 'Select the products to exclude for back-orders.',
          backorderProductsIncludedCardDescription: 'Select the products to include for back-orders.',

          backorderInventoryPolicyTitle: 'Inventory policy',
          backorderInventoryPolicyDescription: `If opting into ${APP_NAME} managing this, we will automatically turn this setting on for all relevant products when back-orders are activated, and turn it off again when back-orders are deactivated.`,
          backorderInventoryPolicyCardTitle: 'Continue selling when out of stock',
          backorderInventoryPolicyCardDescription: 'Products must be set to continue selling when out of stock.',
          backorderFieldIsContinueSellingManagedNo: 'I will manually manage this.',
          backorderFieldIsContinueSellingManagedYes: `${APP_NAME} should automatically manage this (recommended).`,

          // Campaign metrics
          backordersMetricsRevenue: 'Back-order revenue',
          backordersMetricsBackorders: 'Back-orders',

          // Back in stock
          productsTableResourceName: 'Product',
          productsTableResourceNamePlural: 'Products',
          productsTableEmptyTitle: 'No products found',
          productsTableEmptyDescription: 'Check back later to see if any products have new signups.',

          // Back in stock dashboard
          backInStockDashTitle: 'Back in stock',
          backInStockDashTitleSubtitle: 'View and manage your restock notifications.',
          backInStockCtaPublish: 'Activate back in stock',
          backInStockCtaUnpublish: 'Deactivate back in stock',
          backInStockEditGroupCta: 'Configure',

          backInStockStatusActive: 'Active',
          backInStockStatusInactive: 'Inactive',

          backInStockMetricsRevenue: 'Back in stock revenue ({{currency}})',
          backInStockMetricsSignups: 'Signups',
          backInStockMetricsNotifications: 'Emails sent',
          backInStockMetricsConversionRate: 'Conversion rate',
          backInStockMetricsLatestRestock: 'Latest restock',
          backInStockMetricsOpens: 'Opened',
          backInStockMetricsOrders: 'Purchased',

          backInStockProductTableTabAll: 'All',
          backInStockProductTableTabWaiting: 'Waiting',

          backInStockProductTableFieldProduct: 'Product',
          backInStockProductTableFieldVariant: 'Variant',
          backInStockProductTableFieldSignups: 'Signups',
          backInStockProductTableFieldWaiting: 'Waiting',
          backInStockProductTableFieldSent: 'Emails sent',
          backInStockProductTableFieldStock: 'Inventory',
          backInStockProductTableFieldConversionRate: 'Conversion rate',
          backInStockProductTableFieldRevenue: 'Revenue ({{currency}})',
          backInStockProductTableRevenueTooltip: 'The amount of revenue captured as the result of restock emails.',

          backInStockProductTableContactsCount_one: '{{signups}} contact',
          backInStockProductTableContactsCount_other: '{{signups}} contacts',
          backInStockProductTableWaitingCount: '{{waiting}} waiting',
          backInStockProductTableSentCount: '{{sent}} sent',
          backInStockProductTableInventoryCount: '{{inventory}} in stock',

          backInStockConversionRateTooltip: 'The percentage of restock emails which resulted in a purchase. The industry average is around 18%.',
          backInStockConversionRateTooltipBad: 'Well below the industry average. Check for email bounces or consider revising your restock email template.',
          backInStockConversionRateTooltipLow: 'Below the industry average. Check for email bounces or consider revising your restock email template.',
          backInStockConversionRateTooltipAverage: `Close to the industry average. There's room for improvement, but things are working well!`,
          backInStockConversionRateTooltipGood: 'Keeping up with the industry average. Well done!',
          backInStockConversionRateTooltipHigh: "Well above the industry average. Whatever you're doing, it's working!",

          backInStockProductVariantsTableAnyVariant: 'Any variant',

          // Back in stock settings
          backInStockSettingsTitle: 'Back in stock settings',
          backInStockSettingsTitleSubtitle: 'Customize your back in stock settings.',

          backInStockSettingsSubmissionErrorTitle: 'Back in stock settings update failed',
          backInStockSettingsSubmissionError: 'An error occurred while saving the back in stock settings. Please try again or contact us for support.',

          backInStockRestockSubmissionErrorTitle: 'Restock failed to send',
          backInStockRestockSubmissionError: 'An error occurred while sending the restock notification. Please try again or contact us for support.',

          backInStockSelectionTypeTitle: 'Scope',
          backInStockSelectionTypeDescription: 'Choose how to handle selected products.',
          backInStockSelectionTypeCardTitle: 'Back in stock scope',
          backInStockSelectionTypeCardDescription: 'For which products should back in stock be activated?',
          backInStockFieldSelectionTypeInclude: 'Selected products only',
          backInStockFieldSelectionTypeExclude: 'Entire catalog, excluding any products selected below',

          backInStockProductsTitle: 'Products',
          backInStockProductsExcludedDescription: 'Select the products to exclude for back in stock.',
          backInStockProductsIncludedDescription: 'Select the products to include for back in stock.',
          backInStockProductsExcludedCardTitle: 'Excluded products',
          backInStockProductsIncludedCardTitle: 'Included products',
          backInStockProductsExcludedCardDescription: 'Select the products to exclude for back in stock.',
          backInStockProductsIncludedCardDescription: 'Select the products to include for back in stock.',

          backInStockSendTitle: 'Notifications',
          backInStockSendDescription: 'Choose to send restock notifications automatically or manually.',

          backInStockSendMethodCardTitle: 'Restock notifications',
          backInStockSendMethodCardDescription: 'Choose how and when restock notifications are sent.',
          backInStockSendMethodAutomaticYes: 'Automatically when a product is restocked (recommended)',
          backInStockSendMethodAutomaticNo: 'Manually when you choose to notify customers',
          backInStockSendMethodAutomaticInfoTooltip:
            'A stock threshold helps avoid restock notifications being sent when items are returned, or other small increments to stock happen.',
          backInStockSendMethodManualWarningBanner: `Restock notifications won't be sent automatically when you restock unless you opt into ${APP_NAME} managing this.`,

          backInStockFieldAutomaticSendThresholdLabel: 'Stock threshold',
          backInStockFieldAutomaticThresholdDescription: 'The minimum available stock for a product to automatically send restock notifications.',

          backInStockDeliveryTitle: 'Smart sending',
          backInStockDeliveryDescription: `${APP_NAME} sends restock notifications in smart batches to avoid notifying hundreds of customers of a restock when there's only a few units available.`,
          backInStockDeliveryBatchDescription:
            'Restock notifications will be sent in batches based on the amount of stock available, until all signed-up customers are notified, or the product sells out.',

          backInStockBatchCardTitle: 'Smart sending',
          backInStockBatchCardDescription: 'Configure smart sending settings for restock notifications.',
          backInStockFieldBatchMultiplierLabel: 'Batch size',
          backInStockFieldBatchMultiplierDescription: 'We will send emails to {{multiplier}} more customers than available stock in each batch.',
          backInStockFieldBatchDelayLabel: 'Batch delay',
          backInStockFieldBatchDelayDescription: 'We will send a batch of emails every {{delay}}.',
          backInStockFieldBatchSortingLabel: 'Sending order',
          backInStockFieldBatchSortingDescription: 'Which customers should be notified first when a product is restocked?',
          backInStockFieldBatchSortingOldest: 'Oldest signups first',
          backInStockFieldBatchSortingNewest: 'Newest signups first',

          backInStockValidationSendThreshold: 'The stock threshold must be a number greater than 0.',
          backInStockValidationSendThresholdRequired: 'A stock threshold is required when automatic sending is enabled.',

          // Back in stock product
          backInStockProductSendEmailCta: 'Send restock notifications',
          backInStockProductViewBreakdownCta: 'View variant breakdown',

          backInStockContactTableTitle: 'Signups',
          backInStockContactTableSubtitle: 'View and manage contacts who have signed up for restock notifications for this product.',
          backInStockContactTableFilterAll: 'All',
          backInStockContactTableFieldContactEmail: 'Contact',
          backInStockContactTableFieldVariant: 'Variant',
          backInStockContactTableFieldCreatedAt: 'Signed up',
          backInStockContactTableFieldNotified: 'Notified',
          backInStockContactTableFieldStatus: 'Status',

          backInStockContactTableStatusNotSent: 'Waiting',
          backInStockContactTableStatusSent: 'Sent',
          backInStockContactTableStatusSending: 'Sending',
          backInStockContactTableStatusDelivered: 'Delivered',
          backInStockContactTableStatusOpened: 'Opened',
          backInStockContactTableStatusClicked: 'Clicked',
          backInStockContactTableStatusBounced: 'Bounced',

          backInStockStatusConvertedTooltip: 'This restock notification resulted in a purchase.',

          backInStockContactTableVariantTitleFallback: 'Any variant',
          backInStockContactTableActionRemoveContact: 'Remove contact',
          backInStockContactTableActionRemoveContactToast: 'Contact removed',
          backInStockContactTableActionViewEmail: 'View email report',
          backInStockContactTableActionViewOrder: 'View order',

          backInStockContactTableResourceName: 'Contact',
          backInStockContactTableResourceNamePlural: 'Contacts',
          backInStockContactTableEmptyTitle: 'No contacts found',
          backInStockContactTableEmptyDescription: 'Check back later to see if any new contacts have signed up.',

          backInStockRestockTableTitle: 'Restock notification history',
          backInStockRestockTableSubtitle: 'A history of automatic and manual restock notification sends for this product.',
          backInStockRestockTableFieldCreatedAt: 'Started',
          backInStockRestockTableFieldCompletedAt: 'Ended',
          backInStockRestockTableFieldStatus: 'Status',
          backInStockRestockTableFieldIsAutomatic: 'Trigger',
          backInStockRestockTableFieldSendCount: 'Emails sent',
          backInStockRestockTableFieldTriggerAutomatic: 'Automatic',
          backInStockRestockTableFieldTriggerManual: 'Manual',
          backInStockRestockTableStatusError: 'Error',
          backInStockRestockTableStatusComplete: 'Complete',
          backInStockRestockTableStatusWaitingNextBatch: 'Partially complete',
          backInStockRestockTableStatusSending: 'Sending',

          backInStockRestockTableWaitingNextBatchTooltip: 'Current batch completed. Next batch has been scheduled for {{date}}.',

          backInStockRestockTableErrorCodeGeneric: 'An error occurred. Please contact support if errors persist.',
          backInStockRestockTableErrorCodeExceededUsage: 'This restock failed to send because you exceeded your monthly email limit.',
          backInStockRestockTableErrorCodePartialExceededUsage:
            'This restock was only partially sent because you reached your monthly email limit. Please upgrade your plan to finish sending.',

          backInStockRestockTableErrorCodeNonActiveCampaign: "This product is part of a pre-order campaign which wasn't active, so the restock ended early.",
          backInStockRestockTableErrorCodeNoStockAuto: 'No variants met the stock threshold limit for this product, so the restock ended early.',
          backInStockRestockTableErrorCodeNoStockManual: 'No variants were in stock for this product, so the restock ended early.',
          backInStockRestockTableErrorCodeNoContacts:
            "No contacts were waiting to be sent to, or their chosen variants weren't in stock, so the restock ended early.",

          backInStockRestockTableResourceName: 'Restock',
          backInStockRestockTableResourceNamePlural: 'Restocks',
          backInStockRestockTableEmptyTitle: 'No restock events found',
          backInStockRestockTableEmptyDescription: 'Check back after restocking this product, or manually sending notifications.',

          backInStockRestockModalTitle: 'Send restock notifications',
          backInStockRestockModalWarningBanner:
            'Restock notifications will only send if you have in-stock variants for this product, and at least one contact waiting to be notified.',
          backInStockRestockModalDescription:
            "This will <1>immediately email</1> any signed-up customers who haven't received a restock notification yet, based on your back in stock settings.",

          backInStockRestockModalEmailPreviewTitle: 'Email preview',

          backInStockRestockModalCta: 'Send restock notifications',
          backInStockRestockModalCancelCta: 'Cancel',

          // Back in stock contact
          backInStockEditCustomerCta: 'View customer',
          backInStockContactDeleteCta: 'Remove from list',
          backInStockContactNoEmailTitle: 'Customer waiting for restock',
          backInStockContactNoEmailDescription: 'No restock notification has been sent to this customer yet.',
          backInStockContactNoEmailCta: 'Back to product dashboard',

          backInStockContactConvertedTitle: '{{name}} purchased a restocked item',
          backInStockContactConvertedDescription: 'Good news! This restock notification resulted in an order.',
          backInStockContactConvertedDescriptionWithAmount: 'Good news! This restock notification email resulted in an order totalling {{amount}}.',
          backInStockContactConvertedCta: 'View order',

          backInStockEmailEventsTableTitle: 'Email events',
          backInStockEmailEventsTableSubtitle: 'A history of customer activity for this restock email.',

          backInStockEmailPreviewTitle: 'Email preview',
          backInStockEmailPreviewSubtitle: 'A preview of the restock email that was sent to this contact.',

          backInStockEmailEventsTableResourceName: 'Event',
          backInStockEmailEventsTableResourceNamePlural: 'Events',
          backInStockEmailEventsTableEmptyTitle: 'No email events found',
          backInStockEmailEventsTableEmptyDescription: 'Check back later to see if any new email events have been triggered.',
          backInStockEmailEventsTableFieldType: 'Event',
          backInStockEmailEventsTableFieldCreatedAt: 'Date',

          backInStockEmailEventsTableTypeSent: 'Sent',
          backInStockEmailEventsTableTypeDelivered: 'Delivered',
          backInStockEmailEventsTableTypeDeliveryDelayed: 'Delivery delayed',
          backInStockEmailEventsTableTypeComplained: 'Marked as spam',
          backInStockEmailEventsTableTypeBounced: 'Bounced',
          backInStockEmailEventsTableTypeOpened: 'Opened',
          backInStockEmailEventsTableTypeClicked: 'Clicked',
          backInStockEmailEventsTableTypeSubscriptionChange: 'Unsubscribed',

          backInStockSettingsUpdatedToast: 'Back in stock settings updated',
          backInStockRestockStartedToast: 'Restock event started',

          // Pre-orders/campaigns
          campaignsTitle: 'Pre-order campaigns',
          campaignsSubtitle: 'View and manage your pre-order campaigns.',
          campaignsDashTitle: 'Campaign overview',
          campaignsDashTitleLoading: 'Loading...',
          campaignsDashSubtitle: 'Manage and analyze your pre-order campaign.',
          campaignsCta: 'Create campaign',

          // Manage campaign dashboard
          campaignsManageCtaPublish: 'Publish campaign',
          campaignsManageCtaResume: 'Resume campaign',
          campaignsManageCtaUnpublish: 'Stop campaign',
          campaignsManageCtaSchedule: 'Schedule campaign',
          campaignsManageCtaArchive: 'Archive campaign',
          campaignsManageCtaCancel: 'Stop campaign',
          campaignsManageEditGroupCta: 'Edit',
          campaignsManageEditDisplayCta: 'Customize display',
          campaignsManageDeleteCta: 'Delete campaign',
          campaignsManageDuplicateCta: 'Duplicate',
          campaignsManageEditSettingsCta: 'Settings',
          campaignsManageReportCta: 'View sales breakdown',
          campaignsManagePauseModalTitle: 'Stop campaign',
          campaignsManagePauseModalDescription: `${APP_NAME} can set the stock for all variants in this campaign to 0. This will prevent customers from purchasing these products as normal in-stock items.`,
          campaignsManagePauseModalResetStockLabel: 'Yes, set variant stock to 0 to prevent in-stock purchases',
          campaignsManagePauseModalWarning: `Stopping this campaign will remove ${APP_NAME} from these products and revert them to your theme's default layout.`,
          campaignsManagePauseModalLeaveStockCta: 'Stop campaign',
          campaignsManagePauseModalSetStockCta: 'Stop campaign and reset stock',
          campaignsManageResumeModalTitle: 'Resume campaign',
          campaignsManageResumeModalDescription:
            'Resuming this campaign will make these products available for pre-order again. Would you like to reset the stock level for each variant to {{stock}}?',
          campaignsManageResumeModalResetStockLabel: 'Yes, set variant stock to {{stock}}',
          campaignsManageResumeModalResetStockCta: 'Resume campaign and set stock to {{stock}}',
          campaignsManageResumeModalLeaveStockCta: 'Resume campaign',

          campaignsManagePublishModalTitle: 'Publish campaign',
          campaignsManagePublishModalDescription:
            'Before publishing this campaign, please review the <1>requirements and restrictions</1> for pre-orders, which include:',
          campaignsManagePublishModalConfirmLabel: 'I have read and understood these requirements',
          campaignsManagePublishModalPublishCta: 'Publish campaign',
          campaignsManagePublishModalListItem1:
            'Pre-order products are only supported on the Online Store and Custom Storefront sales channels, and may be removed from other channels. You can unpublish this campaign to revert product setting changes.',
          campaignsManagePublishModalListItem2: 'Pre-orders are currently only available when using Shopify Payments or Paypal Express.',
          campaignsManagePublishModalListItem3:
            'Some themes features may not be compatible with pre-order products (e.g. quick-add buttons). Please <1>contact us</1> if you notice any styling or layout issues.',

          // Unavailable products banner
          campaignsUnavailableProductsTitle: 'Product conflict detected',
          campaignsUnavailableProductsDescription:
            'This campaign contains products that already exist in another active campaign. Remove the conflicting products, or adjust your start and end date to publish this campaign. The conflicting campaigns are:',
          campaignsUnavailableProductsCta: 'Edit campaign',

          // Will end banner
          campaignsWillEndTitle: 'Campaign scheduled to stop',
          campaignsWillEndDescription: 'This campaign will automatically stop on {{date}}.',

          // Archived banner
          campaignsArchivedTitle: 'This campaign has been archived',
          campaignsArchivedDescription: 'You archived this campaign on {{date}}. It cannot be edited.',

          // Pause-ended banner
          campaignsPausedEndedTitle: 'Campaign has reached its end date',
          campaignsPausedEndedDescription: 'This campaign reached its end date and was automatically stopped on {{date}}.',

          // Publishing progress/checklist
          campaignsChecklistScheduledTitle: 'This campaign has been scheduled',
          campaignsChecklistUpdatingTitle: 'Processing campaign...',
          campaignsChecklistUpdatedTitle: 'This campaign has finished processing',
          campaignsChecklistUnpublishingTitle: 'Unpublishing campaign...',
          campaignsChecklistUnpublishedTitle: 'This campaign has been unpublished',
          campaignsChecklistProcessingDescription:
            'Please wait while we process this campaign. This may take a few moments. You can leave this page and come back if needed.',

          campaignsScheduledDescription: 'Scheduled for publishing on {{date}}.',

          campaignsChecklistItemProcessing: 'Processing campaign (approx 5 seconds)',
          campaignsChecklistItemUpdatingProducts_one: 'Updating {{count}} product (approx {{eta}})',
          campaignsChecklistItemUpdatingProducts_other: 'Updating {{count}} products (approx {{eta}})',
          campaignsChecklistItemUpdatingCampaign: 'Updating purchase options (approx 5 seconds)',
          campaignsChecklistItemUpdated: 'Campaign has finished processing',

          // Campaign delayed banner
          campaignDelayedTitle: 'Pre-order fulfillment date has been changed',
          campaignDelayedDescription: 'You have changed the fulfillment date to {{date}}. Would you like to update scheduled orders and notify customers?',
          campaignDelayedCta: 'Update orders and notify customers',
          campaignDelayedOrdersOnlyCta: 'Update orders only',
          campaignDelayedOrdersOnlyToastSuccess: 'Scheduled orders updated',

          campaignDelayedChecklistTitle: 'Updating fulfillment date...',
          campaignDelayedChecklistCompletedTitle: 'Fulfillment date updated.',

          campaignDelayedChecklistScheduling: 'Preparing to update (approx 60 seconds)',
          campaignDelayedChecklistQueueing_one: 'Checking {{count}} order (approx {{eta}})',
          campaignDelayedChecklistQueueing_other: 'Checking {{count}} orders (approx {{eta}})',
          campaignDelayedChecklistProcessing_one: 'Notifying {{count}} customer (approx {{eta}})',
          campaignDelayedChecklistProcessing_other: 'Notifying {{count}} customers (approx {{eta}})',
          campaignDelayedChecklistCompleted_one: '{{count}} customer has been notified',
          campaignDelayedChecklistCompleted_other: '{{count}} customers have been notified',

          campaignDelayedChecklistErrorTitle: 'Error updating fulfillment dates',
          campaignDelayedChecklistErrorDescription:
            'Something went wrong while trying to update fulfillment dates. Please refresh and try again or contact support.',

          // Campaign archive modal
          campaignArchiveTitle: 'Archive campaign',
          campaignArchiveDescription: 'Are you sure you want to archive this campaign?',
          campaignArchiveBanner:
            'Archiving a campaign indicates it has finished and prevents it from being edited. Analytics and reports will still be available.',
          campaignArchiveErrorBanner: 'An error occurred when trying to archive this campaign. Please refresh the page to try again or contact us for support.',
          campaignArchiveCta: 'Archive campaign',

          // Campaign duplicate modal
          campaignDuplicateTitle: 'Duplicate campaign',
          campaignDuplicateDescription: 'Are you sure you want to duplicate this campaign?',
          campaignDuplicateBanner:
            'Duplicating a campaign copies all existing settings, display options and purchase options to a new campaign. Orders and analytics will not be copied.',
          campaignDuplicateErrorBanner:
            'An error occurred when trying to duplicate this campaign. Please refresh the page to try again or contact us for support.',
          campaignDuplicateCta: 'Duplicate campaign',

          // Campaign delete modal
          campaignDeleteTitle: 'Delete campaign',
          campaignDeleteDescription: 'Are you sure you want to delete this campaign? This cannot be undone.',
          campaignDeleteBanner: `Campaigns can only be deleted if they are inactive and haven't received any orders.`,
          campaignDeleteErrorBanner: 'An error occurred when trying to delete this campaign. Please refresh the page to try again or contact us for support.',
          campaignDeleteCta: 'Delete campaign',

          // Campaign delay fulfillment modal
          campaignDelayFulfillmentModalTitle: 'Update orders and notify customers',
          campaignDelayFulfillmentModalBanner: 'Check the preview of your email template before sending and make edits via the Email Templates page (placeholder tags will be replaced for each order).',
          campaignDelayFulfillmentModalErrorBanner: 'An error occurred when trying to update orders. Please refresh the page to try again or contact us for support.',
          campaignDelayFulfillmentModalCta: 'Update orders and email customers',
          campaignDelayFulfillmentModalEditTemplateCta: 'Edit email template',
          campaignDelayFulfillmentModalConfirm: 'I understand that any pre-orders with a fulfillment status "Scheduled" will be updated to the newly selected fulfillment date, and customers will be notified via email.',

          // Campaign metrics
          campaignsMetricsStatus: 'Campaign status',
          campaignsMetricsRevenue: 'Pre-order revenue ({{currency}})',
          campaignsMetricsPreorders: 'Pre-orders',
          campaignsMetricsProduct_one: 'Product',
          campaignsMetricsProduct_other: 'Products',
          campaignsMetricsSignups: 'Signups',

          // Campaigns table
          campaignsTableResourceName: 'campaign',
          campaignsTableResourceNamePlural: 'campaigns',
          campaignsTableEmptyTitle: 'No campaigns found',
          campaignsTableEmptyDescription: 'Try adjusting your filters or creating your first campaign to get started.',

          campaignsTableTabAll: 'All',
          campaignsTableTabDraft: 'Draft',
          campaignsTableTabActive: 'Active',
          campaignsTableTabPaused: 'Stopped',
          campaignsTableTabScheduled: 'Scheduled',
          campaignsTableTabArchived: 'Archived',

          campaignsTableFieldName: 'Campaign',
          campaignsTableFieldStatus: 'Status',
          campaignsTableFieldStartDate: 'Start',
          campaignsTableFieldEndDate: 'End',
          campaignsTableFieldOrders: 'Orders',
          campaignsTableFieldRevenue: 'Revenue ({{currency}})',

          // Campaigns units sold breakdown
          salesBreakdownTitle: 'Sales breakdown ({{currency}})',
          salesBreakdownProduct: 'Product',
          salesBreakdownUnitsSold: 'Units sold',
          salesBreakdownRevenue: 'Revenue',

          // Campaign add/edit form
          campaignsManageTitle: 'Manage campaign',
          campaignsCreateTitle: 'Create campaign',
          campaignsEditTitle: 'Edit campaign',
          campaignsCreatedToast: 'Campaign created',
          campaignsUpdatedToast: 'Campaign updated',
          campaignSubmissionErrorTitle: 'Campaign update failed',
          campaignSubmissionError: 'An error occurred while saving this campaign. Please try again or contact us for support.',

          campaignsDetailsTitle: 'Campaign details',
          campaignsDetailsDescription: 'Basic campaign details for internal purposes.',
          campaignsDetailsCardTitle: 'Campaign name',
          campaignsDetailsCardDescription: 'This name is for internal purposes only.',
          campaignsFieldName: 'Campaign name',

          campaignsAvailabilityTitle: 'Availability',
          campaignsAvailabilityDescription: `Control stock limits and when ${APP_NAME} should show the pre-order purchase options to customers.`,

          campaignsAvailabilityCardTitle: 'Availability',
          campaignFieldPreorderTriggeDisabledBanner: 'Stop this campaign to change the availability.',
          campaignsFieldPreorderTriggerAlwaysLabel: 'Keep selling pre-orders until the campaign ends',
          campaignsFieldPreorderTriggerAlwaysDescription: 'Regardless of stock levels, customers can pre-order these products.',
          campaignsFieldPreorderTriggerInStockLabel: 'Only sell pre-orders while in stock',
          campaignsFieldPreorderTriggerInStockDescription: 'Customers can only pre-order these products while stock is above zero.',
          campaignsFieldPreorderTriggerOutOfStockLabel: 'Only sell pre-orders while out of stock',
          campaignsFieldPreorderTriggerOutOfStockDescription: 'Customers can only pre-order these products while stock is zero.',
          campaignsFieldStockLimitLabel: 'Limit pre-order stock',
          campaignsFieldStockLimit: 'Variant stock limit',
          campaignsFieldStockLimitDescription: `${APP_NAME} will set this as the available stock for each variant.`,

          campaignDurationTitle: 'Schedule',
          campaignDurationDescription: 'Campaigns will automatically stop on their end date.',
          campaignDurationCardTitle: 'Duration',
          campaignDurationCardDescription: `Schedule the start and end of the pre-order campaign. ${APP_NAME} will automatically stop the campaign at the desired time if an end date is set.`,
          campaignFieldStartDateDisabledBanner: 'Stop this campaign to change the start date.',
          campaignFieldStartDateLabel: 'Start date',
          campaignFieldStartTimeLabel: 'Start time',
          campaignFieldHasEndDateLabel: 'Set end date',
          campaignFieldEndDateLabel: 'End date',
          campaignFieldEndTimeLabel: 'End time',
          campaignFieldTimezoneNotice: 'Dates are displayed and processed in your shop timezone ({{timezone}}).',
          campaignFieldTimezoneUnknown: 'Unknown',

          campaignProductsTitle: 'Products',
          campaignProductsDescription: 'Select the products and variants to include in this pre-order campaign.',
          campaignProductsDisabledBanner: 'Stop this campaign to change the selected products.',
          campaignProductsCardTitle: 'Selected products',
          campaignProductsCardDescription: 'Select the products and variants to include in this campaign.',
          campaignProductsUnavailableTooltip: 'This product is already in an active campaign.',

          campaignStockLimitTitle: 'Stock limit',
          campaignsFieldPreorderStockLimitManagedNo: 'I will manually manage stock levels.',
          campaignsFieldPreorderStockLimitManagedYes: `${APP_NAME} should automatically manage stock levels.`,
          campaignsFieldPreorderStockLimitDisabledBanner: 'Updates to stock limits must be made on products directly.',

          campaignInventoryPolicyTitle: 'Inventory policy',
          campaignInventoryPolicyDescription: `If opting into ${APP_NAME} managing this, we will automatically turn this setting on for all selected products when the campaign is published, and turn it off again when the campaign is unpublished.`,
          campaignInventoryPolicyDisabledBanner: 'Stop this campaign to change the inventory policy.',

          campaignInventoryPolicyCardTitle: 'Continue selling when out of stock',
          campaignInventoryPolicyCardDescription: 'Continue selling when out of stock must be turned on for these products.',
          campaignInventoryPolicyInlineWarning: `Pre-order products may not be purchasable unless you set them to continue selling when out of stock, or opt into ${APP_NAME} managing this.`,

          campaignInventoryPolicyStockLimitCardTitle: 'Continue selling when out of stock',
          campaignInventoryPolicyStockLimitCardDescription: 'Continue selling when out of stock must be turned off for these products.',
          campaignInventoryPolicyStockLimitInlineWarning: `Stock limits won't be enforced for pre-order products unless you set them to only sell while in stock, or opt into ${APP_NAME} managing this.`,

          campaignFieldIsContinueSellingManagedNo: 'I will manually manage this.',
          campaignFieldIsContinueSellingManagedYes: `${APP_NAME} should automatically manage this (recommended).`,

          campaignPurchaseOptionsTitle: 'Purchase options',
          campaignPurchaseOptionsDescription:
            'Configure purchase options for this pre-order campaign. If both options are active, customers can choose their preferred method.',
          campaignPurchaseOptionsCardTitle: 'Purchase options',
          campaignPurchaseOptionsCardDescription: 'At least one purchase option must be active.',
          campaignPurchaseOptionsDisabledBanner: 'Unpublish this campaign to customize the purchase options.',

          campaignFieldHasFullPlan: 'Pay in full',
          campaignFieldHasFullPlanDescription: 'Customers pay the full price upfront.',

          campaignFieldFullPlan: 'Descriptive name',
          campaignFieldFullPlanDescription: 'This name will be shown to customers.',
          campaignFieldFullPlanPlaceholder: 'e.g. Pay in full now, save 10%',

          campaignFieldHasDiscount: 'Apply a discount',
          campaignFieldDiscountType: 'Discount type',
          campaignFieldDiscountAmount: 'Discount amount',
          campaignFieldDiscountTypePercentage: 'Percentage',
          campaignFieldDiscountTypeFixed: 'Amount',

          campaignFieldHasPartialPlan: 'Pay a deposit',
          campaignFieldHasPartialPlanDescription: 'Customers pay a deposit upfront, then pay the rest at a later date.',
          campaignFieldPartialPlanType: 'Deposit type',
          campaignFieldPartialPlanAmount: 'Deposit amount',
          campaignFieldPartialPlanTypePercentage: 'Percentage',
          campaignFieldPartialPlanTypeFixed: 'Amount',

          campaignFieldPartialPlanName: 'Descriptive name',
          campaignFieldPartialPlanNameDescription: 'This name will be shown to customers.',
          campaignFieldPartialPlanPlaceholder: 'e.g. Pay a 20% deposit now, save 10%',

          campaignFieldPartialPlanFinalPaymentTrigger: 'Final payment is auto-collected',
          campaignFieldPartialPlanTimeAfterCheckout: 'After a specific number of days',
          campaignFieldPartialPlanExactTime: 'On a specific date',
          campaignFieldPartialPlanNumberOfDays: 'Number of days',
          campaignFieldPartialPlanPaymentDate: 'Final payment date',
          campaignFieldPartialPlanPaymentTime: 'Final payment time',

          campaignFulfillmentTitle: 'Fulfillment',
          campaignFulfillmentDescription: 'Configure how stock is managed and when pre-orders will be ready to be fulfilled.',
          campaignFulfillmentCardTitle: 'Pre-order fulfillment',
          campaignFulfillmentCardDescription: 'Configure how stock is managed and when pre-orders will be ready to be fulfilled.',

          campaignFieldInventoryReserve: 'Reserve pre-order stock',
          campaignFieldInventoryReserveOnSale: 'When the pre-order is placed',
          campaignFieldInventoryReserveOnFulfillment: 'When the pre-order is fulfilled',
          campaignFieldInventoryReserveDisabledBanner: 'When using stock limits, inventory must be reserved when the pre-order is placed.',

          campaignFieldFulfillmentTrigger: 'Expected fulfillment date',
          campaignFieldFulfillmentTriggerDescription: 'This information will be shown to customers.',
          campaignFieldFulfillmentTriggerAsap: 'As soon as possible',
          campaignFieldFulfillmentTriggerUnknown: 'Not sure',
          campaignFieldFulfillmentTriggerExactTime: 'On a specific date',
          campaignFieldFulfillmentDate: 'Fulfillment date',
          campaignFieldFulfillmentTime: 'Fulfillment time',

          // Campaign form validation
          validationNameRequired: 'Campaign name is required',

          validationStartDateRequired: 'Start date is required',

          validationEndDateRequired: 'End date is required',
          validationEndDateAfterStartDate: 'End date must be after start date',

          validationStockLimitRange: 'Stock limit must be greater than 0',

          validationProductsRequired: 'Select at least one product or variant',
          validationProductsMaxLimit_one: 'A campaign can have a maximum of {{count}} product',
          validationProductsMaxLimit_other: 'A campaign can have a maximum of {{count}} products',

          validationPurchaseOptionActive: 'Select at least one purchase option',
          validationPurchaseOptionNameRequired: 'Purchase option name is required',

          validationFulfillmentDateRequired: 'Fulfillment date is required',
          validationFulfillmentDateAfterStartDate: 'Fulfillment date must be after start date',
          validationFulfillmentDateAfterEndDate: 'Fulfillment date must be after end date',

          validationSellingPlanDiscountPercentage: 'Discount amount must be between 1% and 99%',
          validationSellingPlanDiscountFixed: 'Discount amount must be greater than 0',
          validationSellingPlanDiscountInvalid: 'Discount amount is invalid',

          validationPartialPlanPartialTypePercentageRange: 'Deposit amount must be between 0% and 99%',
          validationPartialPlanPartialTypeFixedRange: 'Deposit amount must be greater than 0',
          validationPartialPlanDiscountRequired: 'Discount amount must be between 1% and 100%',

          validationPartialPlanPaymentDueDateRequired: 'Final payment date is required',
          validationPartialPlanNumberOfDaysRequired: 'Number of days must be greater than 0',

          // Product selector
          productSelectorSearchLabel: 'Products',
          productSelectorSearchPlaceholder: 'Search products',
          productSelectorAllVariants: 'All variants',
          productSelectorSpecificVariants: '{{count}} of {{total}} variants selected',
          productSelectorMetaCount_one: '{{count}} item selected',
          productSelectorMetaCount_other: '{{count}} items selected',
          productSelectorMetaPagination_one: '{{start}} - {{end}} of {{count}} product',
          productSelectorMetaPagination_other: '{{start}} - {{end}} of {{count}} products',

          // Campaign display
          campaignDisplayTitle: '{{campaignName}} display',
          campaignDisplaySubtitle: 'Manage and analyze your pre-order campaign.',
          campaignDisplayCreateCta: 'Create override',
          campaignDisplayResetCta: 'Remove override',

          // Email templates
          emailTemplatesTitle: 'Email templates',
          emailTemplatesSubtitle: `Customize the emails sent by ${APP_NAME}`,

          emailTemplatesSubmissionErrorTitle: 'Email settings update failed',
          emailTemplatesSubmissionError: 'An error occurred while saving the email settings. Please try again or contact us for support.',

          emailTemplatesPreorderDateChangedTitle: 'Pre-order fulfillment date changed',
          emailTemplatesPreorderDateChangedDescription: `If your pre-order fulfillment date changes, we'll send this email to the relevant customers.`,

          emailTemplatesBackInStockTitle: 'Back in stock',
          emailTemplatesBackInStockDescription: `When a product comes back in stock, we'll send this email to the relevant customers.`,

          emailTemplatesTabEdit: 'Edit',
          emailTemplatesTabPreview: 'Preview',
          emailTemplatesTabTemplate: 'Template',

          emailTemplatesFieldHasCustomTemplate: 'Use custom email template',
          emailTemplatesFieldHasCustomTemplateHelp: 'Provide your own HTML template for this email.',
          emailTemplatesFieldSubject: 'Subject',
          emailTemplatesFieldFrom: 'From',
          emailTemplatesFieldTo: 'To',
          emailTemplatesFieldReplyTo: 'Reply To',
          emailTemplatesFieldTitle: 'Title',
          emailTemplatesFieldBody: 'Body content',
          emailTemplatesFieldBodyHelpText: 'Learn more about <1>dynamic template tags</1> in our Help Center.',
          emailTemplatesFieldPrimaryCta: 'Primary button text',
          emailTemplatesFieldPrimaryCtaHelpText: 'This button will navigate customers to their order status page.',
          emailTemplatesCustomizeTemplate: 'Customize template',
          emailTemplatesResetTemplate: 'Reset to default',
          emailTemplatesUpdatedToast: 'Email templates updated',

          // Display
          displayTitle: 'Display',
          displaySubtitle: `Customize the styling of all ${APP_NAME} components which appear on your storefront.`,

          displaySubmissionErrorTitle: 'Display settings update failed',
          displaySubmissionError: 'An error occurred while saving the display settings. Please try again or contact us for support.',

          displayPreorderTitle: 'Pre-orders',
          displayPreorderDescription: 'Customize styles and messaging for all three pre-order campaign states.',

          displayPreorderTabPreorder: 'Pre-order',
          displayPreorderTabComingSoon: 'Coming soon',
          displayPreorderTabSoldOut: 'Not available',

          displayPreorderButtonTitle: 'Add to cart button',
          displayPreorderButtonDescription: 'Customize the add to cart button for pre-orders.',
          displayPreorderButtonLabel: 'Button text',
          displayPreorderButtonPlaceholder: 'Add pre-order to cart',
          displayPreorderButtonBackgroundColorLabel: 'Button background color',
          displayPreorderButtonTextColorLabel: 'Button text color',

          displayComingSoonButtonLabel: 'Coming soon button text',
          displayComingSoonButtonPlaceholder: 'Coming soon',
          displayComingSoonNoteLabel: 'Coming soon notice',

          displaySoldOutButtonLabel: 'Not available button text',
          displaySoldOutButtonPlaceholder: 'Out of stock',
          displaySoldOutNoteLabel: 'Not available notice',

          displayPreorderPurchaseOptionsTitle: 'Purchase options',
          displayPreorderPurchaseOptionsDescription: 'Customize the styling for purchase options.',
          displayPreorderPurchaseOptionsBackgroundColorLabel: 'Discount badge background color',
          displayPreorderPurchaseOptionsTextColorLabel: 'Discount badge text color',

          displayPreorderNotesTitle: 'Information',
          displayPreorderNotesDescription: 'Customize the pre-order information displayed to the customer.',

          displayPreorderNotesPartialPaymentLabel: 'Deferred payment date notice',
          displayPreorderNotesPartialPaymentDescription:
            'Use the {{tag}} tag to display the pre-order final payment date when the customer is paying a deposit.',
          displayPreorderNotesFulfillmentDateLabel: 'Deferred fulfillment date notice',
          displayPreorderNotesFulfillmentDateDescription: `Use the {{tag}} tag to display the pre-order fulfillment date. If you haven't selected a specific fulfillment date, this will display "{{asap}}" or "{{unknown}}".`,

          displayPreorderValidationFulfillmentDateRequired: 'The {{tag}} tag is required',
          displayPreorderValidationFinalPaymentDateRequired: 'The {{tag}} tag is required',

          displayBackorderTitle: 'Back-orders',
          displayBackorderDescription: 'Customize styles and messaging used for back-order products.',

          displayBackorderButtonLabel: 'Button text',
          displayBackorderButtonPlaceholder: 'Add back-order to cart',
          displayBackorderButtonBackgroundColorLabel: 'Button background color',
          displayBackorderButtonTextColorLabel: 'Button text color',

          displayBisTabStandard: 'Default',
          displayBisTabSuccess: 'Success',
          displayBisTabError: 'Error',

          displayBisButtonLabel: 'Button text',
          displayBisButtonPlaceholder: 'Notify me when available',
          displayBisButtonBackgroundColorLabel: 'Button background color',
          displayBisButtonTextColorLabel: 'Button text color',
          displayBisModalBackgroundColorLabel: 'Modal background color',
          displayBisModalTextColorLabel: 'Modal text color',
          displayBisModalTitleLabel: 'Modal title',
          displayBisModalTitlePlaceholder: 'Notify me when available',
          displayBisModalDescriptionLabel: 'Modal description',
          displayBisModalDescriptionPlaceholder: 'Sign up to be notified when this product is available.',
          displayBisFormVariantPlaceholder: '[Product]',
          displayBisFormEmailPlaceholderLabel: 'Email placeholder',
          displayBisFormEmailPlaceholderPlaceholder: 'Enter your email address', // Lol what
          displayBisFormMarketingConsentLabel: 'Marketing consent',
          displayBisFormMarketingConsentPlaceholder: 'I would also like to receive special offers, news, and discounts.',

          displayBisFormSuccessMessageLabel: 'Success message',
          displayBisFormSuccessMessagePlaceholder: "Signup successful! We'll let you know once this item is available.",
          displayBisFormErrorMessageLabel: 'Error message',
          displayBisFormErrorMessagePlaceholder: 'An error occurred. Please refresh and try again.',

          displayBackorderNoteLabel: 'Back-order notice',
          displayBackorderNoteDescription: 'Let customers know that this product is available for back-order.',

          displayCollectionPageTitle: 'Badges',
          displayCollectionPageDescription: 'Customize the badge styles used on product catalogs and product pages.',

          displayPurchaseOptionBadgeBackgroundColorLabel: 'Badge background color',
          displayPurchaseOptionBadgeTextColorLabel: 'Badge text color',

          displayShowPreorderCollectionBadgeLabel: 'Show pre-order badge on products',
          displayShowPreorderCollectionBadgeDescription: 'Will appear on collection pages where at least one variant is in an active pre-order campaign.',
          displayShowBackorderCollectionBadgeLabel: 'Show back-order badge on products',
          displayShowBackorderCollectionBadgeDescription:
            'Will appear on collection pages where all variants are out of stock and ready to be back-ordered. Only appears if no other badge is present.',

          displayBackInStockTitle: 'Back in stock',
          displayBackInStockDescription: `By default, ${APP_NAME} will use the styling provided by your theme. You can customize these styles if needed here.`,

          displayGlobalBannerTitle: 'This campaign is using your global display settings',
          displayGlobalBannerDescription: 'To customize the display settings for this campaign only, create an override.',
          displayGlobalBannerCta: 'Create override',
          displayGlobalBannerCtaSecondary: 'Go to global display settings',

          displayTabEdit: 'Edit',
          displayTabPreview: 'Preview',
          displayUpdatedToast: 'Display settings updated',

          displayPreviewBanner: 'Previews are only an estimation of your storefront. Styling from your theme may affect the final result.',
          displayPreviewSellingPlanFullExample: 'Pay in full',
          displayPreviewSellingPlanPartialExample: '20% deposit',

          displayBisTitle: 'Back in stock',
          displayBisDescription: 'Customize the styles and messaging used for back in stock widgets.',

          displayColorPickerButtonEdit: 'Edit',
          displayColorPickerButtonReset: 'Reset',
          displayColorInheritPlaceholder: 'Inherit from theme',

          // Settings
          settingsTitle: 'Settings',
          settingsSubtitle: `Customize your global ${APP_NAME} settings.`,

          settingsSubmissionErrorTitle: 'Settings update failed',
          settingsSubmissionError: 'An error occurred while saving the settings. Please try again or contact us for support.',

          settingsGeneralTitle: 'General',
          settingsGeneralDescription: `Global settings used across ${APP_NAME} and your storefront.`,
          settingsGeneralDateFormatLabel1: '2024-01-31',
          settingsGeneralDateFormatLabel2: '01/31/2024',
          settingsGeneralDateFormatLabel3: '31/01/2024',
          settingsGeneralDateFormatLabel4: '31 January 2024',
          settingsGeneralDateFormatLabel5: 'Jan 31, 2024',
          settingsGeneralDateFormatLabel6: 'January 31, 2024',
          settingsGeneralDisplayTitle: 'Display',
          settingsGeneralDisplaySubtitle: `Customize the way ${APP_NAME} appears on your storefront.`,

          settingsEmailTitle: 'Email',
          settingsEmailDescription: `Manage email settings across all ${APP_NAME} emails.`,
          settingsEmailSenderTitle: 'Sender settings',
          settingsEmailSenderSubtitle: 'Connect your domain and configure your email sender settings.',
          settingsEmailSenderVerifiedBanner: 'Emails will be sent from {{name}} <{{email}}>.',
          settingsEmailSenderDnsRecordsTitle: 'DNS records',
          settingsEmailSenderDnsRecordsHelpText: 'Add the following DNS records to verify your domain. <1>Learn more</1>',
          settingsEmailSenderDnsType: 'Type',
          settingsEmailSenderDnsName: 'Name',
          settingsEmailSenderDnsValue: 'Value',
          settingsEmailSenderDnsStatus: 'Status',
          settingsEmailSenderDnsVerifyCta: 'Verify DNS records',
          settingsEmailSenderDnsRefreshCta: 'Refresh',

          settingsEmailSenderDnsStatusVerified: 'Verified',
          settingsEmailSenderDnsStatusPending: 'Pending',
          settingsEmailSenderDnsStatusConfirmedNotVerified: 'DNS Pending',
          settingsEmailSenderDnsStatusUnverified: 'Unverified',

          settingsAdvancedTitle: 'Advanced',
          settingsAdvancedDescription: `Manage advanced ${APP_NAME} settings. Please only edit these if instructed by our support team or a developer.`,
          settingsAdvancedSelectorsTitle: 'Custom selectors',
          settingsAdvancedSelectorsSubtitle: `Change the default selectors that ${APP_NAME} uses to integrate with your theme. Please only edit these if instructed by our support team or a developer.`,
          settingsAdvancedDisplayTitle: 'Display',
          settingsAdvancedDisplaySubtitle: 'Customize advanced styling options.',
          settingsAdvancedSelectorsProductTitle: 'Product detail page',
          settingsAdvancedSelectorsCollectionTitle: 'Collection page',
          settingsAdvancedSelectorsQuickViewTitle: 'Quick-add modal',

          settingsFieldCustomCss: 'Custom styling code (CSS)',

          settingsFieldCustomDomain: 'I want to use my own domain',
          settingsFieldDefaultDomain: 'I want to use the default domain',
          settingsFieldCustomDomainHelp: 'You will need to verify your domain before you can use it.',
          settingsFieldFromAddress: 'From address',
          settingsFieldFromAddressConfirmed: 'Your email address has been verified.',
          settingsFieldFromAddressUnconfirmed: 'Check your inbox to verify your email address. <1>Refresh</1>',
          settingsFieldFromName: 'From name',
          settingsFieldReplyToAddress: 'Reply-to address',

          settingsFieldSelectorVariant: 'Variant field',
          settingsFieldSelectorVariantHelp: 'The input used to track variants.',
          settingsFieldSelectorQuantity: 'Quantity field',
          settingsFieldSelectorQuantityHelp: 'The input used for product quantity.',
          settingsFieldSelectorAddToCart: 'Add to cart button',
          settingsFieldSelectorAddToCartHelp: 'The primary add to cart button selector.',
          settingsFieldSelectorBuyItNow: 'Buy it now button',
          settingsFieldSelectorBuyItNowHelp: 'The dynamic checkout button selector.',
          settingsFieldSelectorProductPrice: 'Product price',
          settingsFieldSelectorProductPriceHelp: 'The main product price selector.',
          settingsFieldSelectorCollectionItem: 'Collection item',
          settingsFieldSelectorCollectionItemHelp: 'The collection page selector for individual items.',
          settingsFieldSelectorCollectionButton: 'Collection item button',
          settingsFieldSelectorCollectionButtonHelp: 'The collection item button selector, generally for quick add modals.',
          settingsFieldSelectorCollectionImage: 'Collection item image',
          settingsFieldSelectorCollectionImageHelp: 'The main image selector for collection items, used for badge placement.',
          settingsFieldSelectorQuickViewWrapper: 'Quick-add modal wrapper',
          settingsFieldSelectorQuickViewWrapperHelp: 'An element selector which wraps the quick-add modal itself.',
          settingsFieldSelectorQuickViewProductId: 'Quick-add product input',
          settingsFieldSelectorQuickViewProductIdHelp: 'The hidden input inside the quick-add modal which contains the product ID.',

          settingsUpdatedToast: 'Settings updated',

          settingsValidationFromEmailRequired: 'From address must be a valid email',

          // App Plans
          appPlansTitle: 'Billing',
          appPlansSubtitle: 'Manage your app plan and subscription details. Upgrade now to increase your limits and access new features.',
          appPlansAvailableTitle: 'Available plans',
          appPlansAvailableSubtitle: 'No commission fees. All prices in USD.',
          appPlansCurrentPlan: 'Current plan',
          appPlansPopularPlan: 'Popular',
          appPlansChangeCta: 'Select plan',
          appPlansShowAnnualPricingLabel: 'Annual pricing (2 months free)',
          appPlansFrequencyMonth: 'month',
          appPlansFrequencyYear: 'year',
          appPlansDowngradeTitle: 'Downgrade plan',
          appPlansDowngradeDescription: `Please be aware that downgrading your plan may result in a loss of access to certain features and an immediate halt to your campaigns if you have exceeded this plan's usage limits.`,
          appPlansDowngradeCta: 'Downgrade',
          appPlansDowngradeCancelCta: 'Keep current plan',
          appPlansCustomCodeFieldLabel: 'Custom plan code',
          appPlansCustomCodeButtonLabel: 'Submit',
          appPlansCode: 'Code',

          appPlansPreordersCount: '{{limit}} pre-orders per month',
          appPlansEmailsCount: '{{limit}} emails per month',
          appPlansSmsCount: '{{limit}} SMS per month',

          appPlansCustomCodeError: 'Invalid code or usage exceeded.',
          appPlansCustomTrialLine: '{{count}} day trial.',
          appPlansCustomDiscountDurationMonthlyLine_one: 'First {{count}} month billed at {{price}} ({{discount}}% discount), then {{fullPrice}} a month.',
          appPlansCustomDiscountDurationMonthlyLine_other: 'First {{count}} months billed at {{price}} ({{discount}}% discount), then {{fullPrice}} a month.',
          appPlansCustomDiscountDurationAnnualLine_one: 'First {{count}} year billed at {{price}} ({{discount}}% discount), then {{fullPrice}} a year.',
          appPlansCustomDiscountDurationAnnualLine_other: 'First {{count}} years billed at {{price}} ({{discount}}% discount), then {{fullPrice}} a year.',
          appPlansCustomDiscountIndefiniteLine: '{{discount}}% off all charges.',

          appPlansFreeTitle: 'Free',
          appPlansFreeDescription: 'Ideal for new stores to try offering pre-orders and gauging interest with back in stock notifications.',
          appPlansFreeFeature1: 'Basic analytics',
          appPlansFreeFeature2: 'Basic styling options',

          appPlansLaunchTitle: 'Launch',
          appPlansLaunchDescription: 'Ideal for new stores ready to offer pre-orders and send restock emails to capture more sales with advanced features.',
          appPlansLaunchFeature1: 'Partial payments/pay a deposit',
          appPlansLaunchFeature2: 'Automatically capture deferred payments',
          appPlansLaunchFeature3: 'Custom email domain',
          appPlansLaunchFeature4: 'Customize email templates',
          appPlansLaunchFeature5: 'All previous features',

          appPlansGrowTitle: 'Grow',
          appPlansGrowDescription: 'Ideal for growing stores ready to streamline pre-order operations and increase sales volume.',
          appPlansGrowFeature1: 'Priority support',
          appPlansGrowFeature2: 'All previous features',

          appPlansScaleTitle: 'Scale',
          appPlansScaleDescription: 'Ideal for scaling stores ready to optimize pre-orders for higher profitability.',
          appPlansScaleFeature1: 'Priority support',
          appPlansScaleFeature2: '1:1 onboarding support',
          appPlansScaleFeature3: 'All previous features',

          appPlansPartnerTitle: 'Partner',
          appPlansPartnerDescription: 'Ideal for established stores ready to capture a larger market share with regular pre-orders campaigns.',
          appPlansPartnerFeature1: 'All previous features',
          appPlansPartnerFeature2: 'Top priority support',
          appPlansPartnerFeature3: 'Monthly check-ins and strategy review',
          appPlansPartnerFeature4: 'Exclusive early access to new features',

          appPlansCustomTitle: 'Custom',
          appPlansCustomDescription: 'Contact support to organize a custom plan to suit your needs.',
          appPlansCustomPartialPayments: 'Partial payments + auto capture',
          appPlansCustomEmailDomainTemplate: 'Custom email domain and templates',

          // Reporting
          reportCampaignUnitsSoldBannerNoSales: 'No sales data available',
          reportCampaignUnitsSoldBannerNoSalesDescription: 'Check again later to see if any new sales have been made.',
          reportCampaignUnitsSoldBackCta: 'Back to campaign',

          // Back in stock report pages
          reportBackInStockVariantsSubtitle: 'Back in stock variant breakdown report.',

          // Localisation
          localisationTitle: 'Localization',
          localisationSubtitle: `Customize the labels, messaging and language used across ${APP_NAME}.`,

          localisationBadgesTitle: 'Badges',
          localisationPurchaseOptionsTitle: 'Purchase options',
          localisationInformationTitle: 'Information',
          localisationButtonsTitle: 'Collection page',

          localisationSubmissionErrorTitle: 'Localization settings update failed',
          localisationSubmissionError: 'An error occurred while saving the localization settings. Please try again or contact us for support.',

          localisationDateTitle: 'Date localization',
          localisationDateDescription: 'Select the date format and locale to use on your storefront.',

          localisationDateCardTitle: 'Date localization',
          localisationDateCardDescription: 'Dates shown to customers will use these settings when being displayed on your storefront.',

          localisationFieldDateFormat: 'Format',
          localisationFieldDateLocale: 'Locale',

          localisationLabelsTitle: 'Labels and messaging',
          localisationLabelsDescription: `Localize and translate ${APP_NAME}'s messaging. Some labels and messaging must be changed in Display or Email template settings.`,

          localisationLabelsCardTitle: 'Labels and messaging',
          localisationLabelsCardDescription:
            'Customize all labels and messages shown to customers on your storefront. Leaving a field blank will revert it to its default value.',

          localisationFieldPurchaseOptionLabel: 'Purchase option label',
          localisationFieldPurchaseOptionLabelPlaceholder: 'Purchase option',
          localisationFieldPurchaseOptionLabelHelp: 'Shown on product pages and line items during checkout.',

          localisationFieldPreorderLabel: 'Pre-order label',
          localisationFieldPreorderLabelPlaceholder: 'Pre-order',
          localisationFieldPreorderLabelHelp: 'Shown on collection item badges and line items during checkout.',

          localisationFieldBackorderLabel: 'Back-order label',
          localisationFieldBackorderLabelPlaceholder: 'Back-order',
          localisationFieldBackorderLabelHelp: 'Shown on collection item badges and line items during checkout.',

          localisationFieldExpectedShipDatePrefix: 'Expected ship date prefix',
          localisationFieldExpectedShipDatePrefixPlaceholder: 'Expected ship date',
          localisationFieldExpectedShipDatePrefixHelp: 'Shown on line items during checkout (e.g. Expected ship date: {{date}}).',

          localisationFieldQuickAddButtonCta: 'View product button',
          localisationFieldQuickAddButtonCtaPlaceholder: 'View product',
          localisationFieldQuickAddButtonCtaHelp: `Shown as the button text on collection items where ${APP_NAME} has replaced any existing buttons with a button to view the product page (e.g. quick add modals).`,

          localisationFieldDiscountPrefix: 'Discount prefix',
          localisationFieldDiscountPrefixPlaceholder: 'Save',
          localisationFieldDiscountPrefixHelp: 'Shown on purchase options when they are discounted (e.g. Save 20%).',

          localisationFieldAsapLabel: 'ASAP label',
          localisationFieldAsapLabelPlaceholder: 'ASAP',
          localisationFieldAsapLabelHelp:
            'Can show as part of the fulfillment note on pre-orders when the fulfillment date is set to ASAP (e.g. Expected ship date: ASAP).',

          localisationFieldUnknownLabel: 'Unknown label',
          localisationFieldUnknownLabelPlaceholder: 'Unknown',
          localisationFieldUnknownLabelHelp:
            'Can show as part of the fulfillment note on pre-orders when the fulfillment date is set to Unknown (e.g. Expected ship date: Unknown).',

          localisationFieldPreorderStockLimitError: 'Pre-order stock limit error',
          localisationFieldPreorderStockLimitErrorPlaceholder: `Can't add this many items to your cart. Please reduce your quantity and try again.`,
          localisationFieldPreorderStockLimitErrorHelp:
            'Shown on product pages when a customer tries to purchase more stock than is available, based on your stock limits.',

          localisationUpdatedToast: 'Localization settings updated',

          datePickerAllTime: 'All time',
          datePickerToday: 'Today',
          datePickerYesterday: 'Yesterday',
          datePickerLast7Days: 'Last 7 days',
          datePickerLast30Days: 'Last 30 days',
          datePickerLast90Days: 'Last 90 days',
          datePickerLast365Days: 'Last 365 days',
          datePickerCustom: 'Custom',

          datePickerSince: 'Since',
          datePickerUntil: 'Until',

          // Locales
          'dateLocale-af': 'Afrikaans',
          'dateLocale-am': 'Amharic',
          'dateLocale-ar-dz': 'Arabic (Algeria)',
          'dateLocale-ar-iq': ' Arabic (Iraq)',
          'dateLocale-ar-kw': 'Arabic (Kuwait)',
          'dateLocale-ar-ly': 'Arabic (Lybia)',
          'dateLocale-ar-ma': 'Arabic (Morocco)',
          'dateLocale-ar-sa': 'Arabic (Saudi Arabia)',
          'dateLocale-ar-tn': ' Arabic (Tunisia)',
          'dateLocale-ar': 'Arabic',
          'dateLocale-az': 'Azerbaijani',
          'dateLocale-be': 'Belarusian',
          'dateLocale-bg': 'Bulgarian',
          'dateLocale-bi': 'Bislama',
          'dateLocale-bm': 'Bambara',
          'dateLocale-bn-bd': 'Bengali (Bangladesh)',
          'dateLocale-bn': 'Bengali',
          'dateLocale-bo': 'Tibetan',
          'dateLocale-br': 'Breton',
          'dateLocale-bs': 'Bosnian',
          'dateLocale-ca': 'Catalan',
          'dateLocale-cs': 'Czech',
          'dateLocale-cv': 'Chuvash',
          'dateLocale-cy': 'Welsh',
          'dateLocale-da': 'Danish',
          'dateLocale-de-at': 'German (Austria)',
          'dateLocale-de-ch': 'German (Switzerland)',
          'dateLocale-de': 'German',
          'dateLocale-dv': 'Maldivian',
          'dateLocale-el': 'Greek',
          'dateLocale-en-au': 'English (Australia)',
          'dateLocale-en-ca': 'English (Canada)',
          'dateLocale-en-gb': 'English (United Kingdom)',
          'dateLocale-en-ie': 'English (Ireland)',
          'dateLocale-en-il': 'English (Israel)',
          'dateLocale-en-in': 'English (India)',
          'dateLocale-en-nz': 'English (New Zealand)',
          'dateLocale-en-sg': 'English (Singapore)',
          'dateLocale-en-tt': 'English (Trinidad & Tobago)',
          'dateLocale-en': 'English',
          'dateLocale-eo': 'Esperanto',
          'dateLocale-es-do': 'Spanish (Dominican Republic)',
          'dateLocale-es-mx': 'Spanish (Mexico)',
          'dateLocale-es-pr': 'Spanish (Puerto Rico)',
          'dateLocale-es-us': 'Spanish (United States)',
          'dateLocale-es': 'Spanish',
          'dateLocale-et': 'Estonian',
          'dateLocale-eu': 'Basque',
          'dateLocale-fa': 'Persian',
          'dateLocale-fi': 'Finnish',
          'dateLocale-fo': 'Faroese',
          'dateLocale-fr-ca': 'French (Canada)',
          'dateLocale-fr-ch': 'French (Switzerland)',
          'dateLocale-fr': 'French',
          'dateLocale-fy': 'Frisian',
          'dateLocale-ga': 'Irish or Irish Gaelic',
          'dateLocale-gd': 'Scottish Gaelic',
          'dateLocale-gl': 'Galician',
          'dateLocale-gom-latn': 'Konkani Latin script',
          'dateLocale-gu': 'Gujarati',
          'dateLocale-hi': 'Hindi',
          'dateLocale-he': 'Hebrew',
          'dateLocale-hr': 'Croatian',
          'dateLocale-ht': 'Haitian Creole (Haiti)',
          'dateLocale-hu': 'Hungarian',
          'dateLocale-hy-am': 'Armenian',
          'dateLocale-id': 'Indonesian',
          'dateLocale-is': 'Icelandic',
          'dateLocale-it-ch': 'Italian (Switzerland)',
          'dateLocale-it': 'Italian',
          'dateLocale-ja': 'Japanese',
          'dateLocale-jv': 'Javanese',
          'dateLocale-ka': 'Georgian',
          'dateLocale-kk': 'Kazakh',
          'dateLocale-km': 'Cambodian',
          'dateLocale-kn': 'Kannada',
          'dateLocale-ko': 'Korean',
          'dateLocale-ku': 'Kurdish',
          'dateLocale-ky': 'Kyrgyz',
          'dateLocale-lb': 'Luxembourgish',
          'dateLocale-lo': 'Lao',
          'dateLocale-lt': 'Lithuanian',
          'dateLocale-lv': 'Latvian',
          'dateLocale-me': 'Montenegrin',
          'dateLocale-mi': 'Maori',
          'dateLocale-mk': 'Macedonian',
          'dateLocale-ml': 'Malayalam',
          'dateLocale-mn': 'Mongolian',
          'dateLocale-mr': 'Marathi',
          'dateLocale-ms-my': 'Malay',
          'dateLocale-ms': 'Malay',
          'dateLocale-mt': 'Maltese (Malta)',
          'dateLocale-my': 'Burmese',
          'dateLocale-nb': 'Norwegian Bokmål',
          'dateLocale-ne': 'Nepalese',
          'dateLocale-nl-be': 'Dutch (Belgium)',
          'dateLocale-nl': 'Dutch',
          'dateLocale-nn': 'Nynorsk',
          'dateLocale-oc-lnc': 'Occitan, lengadocian dialecte',
          'dateLocale-pa-in': 'Punjabi (India)',
          'dateLocale-pl': 'Polish',
          'dateLocale-pt-br': 'Portuguese (Brazil)',
          'dateLocale-pt': 'Portuguese',
          'dateLocale-rn': 'Kirundi',
          'dateLocale-ro': 'Romanian',
          'dateLocale-sd': 'Sindhi',
          'dateLocale-si': 'Sinhalese',
          'dateLocale-se': 'Northern Sami',
          'dateLocale-sk': 'Slovak',
          'dateLocale-sl': 'Slovenian',
          'dateLocale-sq': 'Albanian',
          'dateLocale-sr-cyrl': 'Serbian Cyrillic',
          'dateLocale-sr': 'Serbian',
          'dateLocale-ss': 'siSwati',
          'dateLocale-sv-fi': 'Finland Swedish',
          'dateLocale-sv': 'Swedish',
          'dateLocale-sw': 'Swahili',
          'dateLocale-ta': 'Tamil',
          'dateLocale-te': 'Telugu',
          'dateLocale-tg': 'Tajik',
          'dateLocale-tet': 'Tetun Dili (East Timor)',
          'dateLocale-th': 'Thai',
          'dateLocale-tk': 'Turkmen',
          'dateLocale-tl-ph': 'Tagalog (Philippines)',
          'dateLocale-tlh': 'Klingon',
          'dateLocale-tr': 'Turkish',
          'dateLocale-tzl': 'Talossan',
          'dateLocale-tzm-latn': 'Central Atlas Tamazight Latin',
          'dateLocale-ug-cn': 'Uyghur (China)',
          'dateLocale-tzm': 'Central Atlas Tamazight',
          'dateLocale-uk': 'Ukrainian',
          'dateLocale-ur': 'Urdu',
          'dateLocale-uz-latn': 'Uzbek Latin',
          'dateLocale-vi': 'Vietnamese',
          'dateLocale-uz': 'Uzbek',
          'dateLocale-yo': 'Yoruba Nigeria',
          'dateLocale-x-pseudo': 'Pseudo',
          'dateLocale-zh-cn': 'Chinese (China)',
          'dateLocale-zh-hk': 'Chinese (Hong Kong)',
          'dateLocale-zh-tw': 'Chinese (Taiwan)',
          'dateLocale-zh': 'Chinese',
          'dateLocale-rw': 'Kinyarwanda (Rwanda)',
          'dateLocale-ru': 'Russian',
        },
      },
      // Other languages
    },
  })

export default i18n