import { useNavigate, createFileRoute } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import i18next from 'i18next'

import { bisSettingQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { BackInStockSettingsForm } from '@/components/backInStock/BackInStockSettingsForm'
import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage, Text } from '@shopify/polaris'
import { BackInStockStatusBadge } from '@/components/shared/BackInStockStatusBadge'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/back-in-stock/settings')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      const settings = await queryClient.ensureQueryData(settingsQueryOptions())
      await queryClient.ensureQueryData(bisSettingQueryOptions({ uuid: settings.bisSettingsId }))
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const navigate = useNavigate()

  const { data: settings } = useSuspenseQuery(settingsQueryOptions())

  return (
    <PageFrame
      title={i18next.t('backInStockSettingsTitle')}
      subtitle={i18next.t('backInStockSettingsTitleSubtitle')}
      titleMetadata={<BackInStockStatusBadge enabled={settings.bisEnabled} />}
      backAction={{
        content: i18next.t('backInStockDashTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: '/back-in-stock' })
        },
      }}
    >
      <BackInStockSettingsForm />
    </PageFrame>
  )
}

function Pending() {
  return (
    <SkeletonPage title={i18next.t('backInStockSettingsTitle')}>
      <Layout>
        <Layout.AnnotatedSection title={i18next.t('backInStockSelectionTypeTitle')} description={i18next.t('backInStockSelectionTypeDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('backInStockProductsTitle')} description={i18next.t('backInStockProductsExcludedDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('backInStockSendTitle')} description={i18next.t('backInStockSendDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection
          title={i18next.t('backInStockDeliveryTitle')}
          description={
            <>
              <Text as="p" variant="bodyMd">
                {i18next.t('backInStockDeliveryDescription')}
              </Text>
              <br />
              <Text as="p" variant="bodyMd">
                {i18next.t('backInStockDeliveryBatchDescription')}
              </Text>
            </>
          }
        >
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
