import { createFileRoute } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import i18next from 'i18next'
import { settingsQueryOptions } from '@/common/queryOptions'
import { SettingsFallback } from '@/components/settings/SettingsFallback'
import { SettingsForm } from '@/components/settings/SettingsForm'

export const Route = createFileRoute('/settings')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      await queryClient.ensureQueryData(settingsQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => <SettingsFallback />,
  component: Index,
})

function Index() {
  return (
    <PageFrame title={i18next.t('settingsTitle')} subtitle={i18next.t('settingsSubtitle')}>
      <SettingsForm />
    </PageFrame>
  )
}
