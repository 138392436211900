// External libraries
import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router'
import { useSuspenseQuery } from '@tanstack/react-query'
import i18next from 'i18next'

// Internal components
import { CampaignStatusBadge } from '@/components/shared/CampaignStatusBadge'
import { PageFrame } from '@/components/shared/PageFrame'
import { CampaignForm } from '@/components/preorders/CampaignForm'

// Utility functions and configurations
import { campaignQueryOptions, settingsQueryOptions, campaignUnavailableProductsQueryOptions } from '@/common/queryOptions'
import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'

export const Route = createFileRoute('/preorders/$uuid/settings')({
  loader: async ({ params: { uuid }, context: { queryClient }, route: { path } }) => {
    try {
      const settingsPromise = queryClient.ensureQueryData(settingsQueryOptions())
      const campaignPromise = queryClient.ensureQueryData(campaignQueryOptions({ uuid }))

      // Get campaign data for the dependent query
      const {
        data: [campaign],
      } = await campaignPromise

      // Start unavailable products query as soon as we have campaign data
      const unavailableProductsPromise = queryClient.ensureQueryData(
        campaignUnavailableProductsQueryOptions({
          uuid: uuid,
          from: campaign.startDate ?? null,
          to: campaign.endDate ?? undefined,
        })
      )

      // Wait for all queries to complete
      await Promise.all([settingsPromise, unavailableProductsPromise])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const navigate = useNavigate()
  const { uuid } = useParams({ strict: false }) as { uuid: string }

  const {
    data: {
      data: [campaign],
    },
  } = useSuspenseQuery(campaignQueryOptions({ uuid }))

  const title = campaign.name ?? i18next.t('campaignsEditTitle')

  return (
    <PageFrame
      title={title}
      subtitle={i18next.t('campaignsDashSubtitle')}
      titleMetadata={<CampaignStatusBadge status={campaign.status} withProgress={false} />}
      backAction={{
        content: i18next.t('campaignsManageTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: '/preorders/$uuid', params: { uuid } })
        },
      }}
    >
      <CampaignForm campaign={campaign} />
    </PageFrame>
  )
}

function Pending() {
  return (
    <SkeletonPage title={i18next.t('genericLoading')} backAction>
      <Layout>
        <Layout.AnnotatedSection title={i18next.t('campaignsDetailsTitle')} description={i18next.t('campaignsDetailsDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignsAvailabilityTitle')} description={i18next.t('campaignsAvailabilityDescription')}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignDurationTitle')} description={i18next.t('campaignDurationDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignProductsTitle')} description={i18next.t('campaignProductsDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignPurchaseOptionsTitle')} description={i18next.t('campaignPurchaseOptionsDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignFulfillmentTitle')} description={i18next.t('campaignFulfillmentDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}
