/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SettingsImport } from './routes/settings'
import { Route as PlansImport } from './routes/plans'
import { Route as LocalizationImport } from './routes/localization'
import { Route as EmailsImport } from './routes/emails'
import { Route as DisplayImport } from './routes/display'
import { Route as IndexImport } from './routes/index'
import { Route as PreordersIndexImport } from './routes/preorders.index'
import { Route as BackordersIndexImport } from './routes/backorders.index'
import { Route as BackInStockIndexImport } from './routes/back-in-stock.index'
import { Route as PreordersCreateImport } from './routes/preorders.create'
import { Route as BackordersSettingsImport } from './routes/backorders.settings'
import { Route as BackInStockSettingsImport } from './routes/back-in-stock.settings'
import { Route as PreordersUuidIndexImport } from './routes/preorders.$uuid.index'
import { Route as PreordersUuidUnitsSoldImport } from './routes/preorders.$uuid.units-sold'
import { Route as PreordersUuidSettingsImport } from './routes/preorders.$uuid.settings'
import { Route as PreordersUuidReportImport } from './routes/preorders.$uuid.report'
import { Route as PreordersUuidDisplayImport } from './routes/preorders.$uuid.display'
import { Route as BackInStockProductsProductIdIndexImport } from './routes/back-in-stock.products.$productId.index'
import { Route as BackInStockProductsProductIdVariantsImport } from './routes/back-in-stock.products.$productId.variants'
import { Route as BackInStockProductsProductIdContactsContactIdImport } from './routes/back-in-stock.products.$productId.contacts.$contactId'

// Create/Update Routes

const SettingsRoute = SettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => rootRoute,
} as any)

const PlansRoute = PlansImport.update({
  id: '/plans',
  path: '/plans',
  getParentRoute: () => rootRoute,
} as any)

const LocalizationRoute = LocalizationImport.update({
  id: '/localization',
  path: '/localization',
  getParentRoute: () => rootRoute,
} as any)

const EmailsRoute = EmailsImport.update({
  id: '/emails',
  path: '/emails',
  getParentRoute: () => rootRoute,
} as any)

const DisplayRoute = DisplayImport.update({
  id: '/display',
  path: '/display',
  getParentRoute: () => rootRoute,
} as any)

const IndexRoute = IndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => rootRoute,
} as any)

const PreordersIndexRoute = PreordersIndexImport.update({
  id: '/preorders/',
  path: '/preorders/',
  getParentRoute: () => rootRoute,
} as any)

const BackordersIndexRoute = BackordersIndexImport.update({
  id: '/backorders/',
  path: '/backorders/',
  getParentRoute: () => rootRoute,
} as any)

const BackInStockIndexRoute = BackInStockIndexImport.update({
  id: '/back-in-stock/',
  path: '/back-in-stock/',
  getParentRoute: () => rootRoute,
} as any)

const PreordersCreateRoute = PreordersCreateImport.update({
  id: '/preorders/create',
  path: '/preorders/create',
  getParentRoute: () => rootRoute,
} as any)

const BackordersSettingsRoute = BackordersSettingsImport.update({
  id: '/backorders/settings',
  path: '/backorders/settings',
  getParentRoute: () => rootRoute,
} as any)

const BackInStockSettingsRoute = BackInStockSettingsImport.update({
  id: '/back-in-stock/settings',
  path: '/back-in-stock/settings',
  getParentRoute: () => rootRoute,
} as any)

const PreordersUuidIndexRoute = PreordersUuidIndexImport.update({
  id: '/preorders/$uuid/',
  path: '/preorders/$uuid/',
  getParentRoute: () => rootRoute,
} as any)

const PreordersUuidUnitsSoldRoute = PreordersUuidUnitsSoldImport.update({
  id: '/preorders/$uuid/units-sold',
  path: '/preorders/$uuid/units-sold',
  getParentRoute: () => rootRoute,
} as any)

const PreordersUuidSettingsRoute = PreordersUuidSettingsImport.update({
  id: '/preorders/$uuid/settings',
  path: '/preorders/$uuid/settings',
  getParentRoute: () => rootRoute,
} as any)

const PreordersUuidReportRoute = PreordersUuidReportImport.update({
  id: '/preorders/$uuid/report',
  path: '/preorders/$uuid/report',
  getParentRoute: () => rootRoute,
} as any)

const PreordersUuidDisplayRoute = PreordersUuidDisplayImport.update({
  id: '/preorders/$uuid/display',
  path: '/preorders/$uuid/display',
  getParentRoute: () => rootRoute,
} as any)

const BackInStockProductsProductIdIndexRoute = BackInStockProductsProductIdIndexImport.update({
  id: '/back-in-stock/products/$productId/',
  path: '/back-in-stock/products/$productId/',
  getParentRoute: () => rootRoute,
} as any)

const BackInStockProductsProductIdVariantsRoute = BackInStockProductsProductIdVariantsImport.update({
  id: '/back-in-stock/products/$productId/variants',
  path: '/back-in-stock/products/$productId/variants',
  getParentRoute: () => rootRoute,
} as any)

const BackInStockProductsProductIdContactsContactIdRoute = BackInStockProductsProductIdContactsContactIdImport.update({
  id: '/back-in-stock/products/$productId/contacts/$contactId',
  path: '/back-in-stock/products/$productId/contacts/$contactId',
  getParentRoute: () => rootRoute,
} as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/': {
      id: '/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof IndexImport
      parentRoute: typeof rootRoute
    }
    '/display': {
      id: '/display'
      path: '/display'
      fullPath: '/display'
      preLoaderRoute: typeof DisplayImport
      parentRoute: typeof rootRoute
    }
    '/emails': {
      id: '/emails'
      path: '/emails'
      fullPath: '/emails'
      preLoaderRoute: typeof EmailsImport
      parentRoute: typeof rootRoute
    }
    '/localization': {
      id: '/localization'
      path: '/localization'
      fullPath: '/localization'
      preLoaderRoute: typeof LocalizationImport
      parentRoute: typeof rootRoute
    }
    '/plans': {
      id: '/plans'
      path: '/plans'
      fullPath: '/plans'
      preLoaderRoute: typeof PlansImport
      parentRoute: typeof rootRoute
    }
    '/settings': {
      id: '/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof SettingsImport
      parentRoute: typeof rootRoute
    }
    '/back-in-stock/settings': {
      id: '/back-in-stock/settings'
      path: '/back-in-stock/settings'
      fullPath: '/back-in-stock/settings'
      preLoaderRoute: typeof BackInStockSettingsImport
      parentRoute: typeof rootRoute
    }
    '/backorders/settings': {
      id: '/backorders/settings'
      path: '/backorders/settings'
      fullPath: '/backorders/settings'
      preLoaderRoute: typeof BackordersSettingsImport
      parentRoute: typeof rootRoute
    }
    '/preorders/create': {
      id: '/preorders/create'
      path: '/preorders/create'
      fullPath: '/preorders/create'
      preLoaderRoute: typeof PreordersCreateImport
      parentRoute: typeof rootRoute
    }
    '/back-in-stock/': {
      id: '/back-in-stock/'
      path: '/back-in-stock'
      fullPath: '/back-in-stock'
      preLoaderRoute: typeof BackInStockIndexImport
      parentRoute: typeof rootRoute
    }
    '/backorders/': {
      id: '/backorders/'
      path: '/backorders'
      fullPath: '/backorders'
      preLoaderRoute: typeof BackordersIndexImport
      parentRoute: typeof rootRoute
    }
    '/preorders/': {
      id: '/preorders/'
      path: '/preorders'
      fullPath: '/preorders'
      preLoaderRoute: typeof PreordersIndexImport
      parentRoute: typeof rootRoute
    }
    '/preorders/$uuid/display': {
      id: '/preorders/$uuid/display'
      path: '/preorders/$uuid/display'
      fullPath: '/preorders/$uuid/display'
      preLoaderRoute: typeof PreordersUuidDisplayImport
      parentRoute: typeof rootRoute
    }
    '/preorders/$uuid/report': {
      id: '/preorders/$uuid/report'
      path: '/preorders/$uuid/report'
      fullPath: '/preorders/$uuid/report'
      preLoaderRoute: typeof PreordersUuidReportImport
      parentRoute: typeof rootRoute
    }
    '/preorders/$uuid/settings': {
      id: '/preorders/$uuid/settings'
      path: '/preorders/$uuid/settings'
      fullPath: '/preorders/$uuid/settings'
      preLoaderRoute: typeof PreordersUuidSettingsImport
      parentRoute: typeof rootRoute
    }
    '/preorders/$uuid/units-sold': {
      id: '/preorders/$uuid/units-sold'
      path: '/preorders/$uuid/units-sold'
      fullPath: '/preorders/$uuid/units-sold'
      preLoaderRoute: typeof PreordersUuidUnitsSoldImport
      parentRoute: typeof rootRoute
    }
    '/preorders/$uuid/': {
      id: '/preorders/$uuid/'
      path: '/preorders/$uuid'
      fullPath: '/preorders/$uuid'
      preLoaderRoute: typeof PreordersUuidIndexImport
      parentRoute: typeof rootRoute
    }
    '/back-in-stock/products/$productId/variants': {
      id: '/back-in-stock/products/$productId/variants'
      path: '/back-in-stock/products/$productId/variants'
      fullPath: '/back-in-stock/products/$productId/variants'
      preLoaderRoute: typeof BackInStockProductsProductIdVariantsImport
      parentRoute: typeof rootRoute
    }
    '/back-in-stock/products/$productId/': {
      id: '/back-in-stock/products/$productId/'
      path: '/back-in-stock/products/$productId'
      fullPath: '/back-in-stock/products/$productId'
      preLoaderRoute: typeof BackInStockProductsProductIdIndexImport
      parentRoute: typeof rootRoute
    }
    '/back-in-stock/products/$productId/contacts/$contactId': {
      id: '/back-in-stock/products/$productId/contacts/$contactId'
      path: '/back-in-stock/products/$productId/contacts/$contactId'
      fullPath: '/back-in-stock/products/$productId/contacts/$contactId'
      preLoaderRoute: typeof BackInStockProductsProductIdContactsContactIdImport
      parentRoute: typeof rootRoute
    }
  }
}

// Create and export the route tree

export interface FileRoutesByFullPath {
  '/': typeof IndexRoute
  '/display': typeof DisplayRoute
  '/emails': typeof EmailsRoute
  '/localization': typeof LocalizationRoute
  '/plans': typeof PlansRoute
  '/settings': typeof SettingsRoute
  '/back-in-stock/settings': typeof BackInStockSettingsRoute
  '/backorders/settings': typeof BackordersSettingsRoute
  '/preorders/create': typeof PreordersCreateRoute
  '/back-in-stock': typeof BackInStockIndexRoute
  '/backorders': typeof BackordersIndexRoute
  '/preorders': typeof PreordersIndexRoute
  '/preorders/$uuid/display': typeof PreordersUuidDisplayRoute
  '/preorders/$uuid/report': typeof PreordersUuidReportRoute
  '/preorders/$uuid/settings': typeof PreordersUuidSettingsRoute
  '/preorders/$uuid/units-sold': typeof PreordersUuidUnitsSoldRoute
  '/preorders/$uuid': typeof PreordersUuidIndexRoute
  '/back-in-stock/products/$productId/variants': typeof BackInStockProductsProductIdVariantsRoute
  '/back-in-stock/products/$productId': typeof BackInStockProductsProductIdIndexRoute
  '/back-in-stock/products/$productId/contacts/$contactId': typeof BackInStockProductsProductIdContactsContactIdRoute
}

export interface FileRoutesByTo {
  '/': typeof IndexRoute
  '/display': typeof DisplayRoute
  '/emails': typeof EmailsRoute
  '/localization': typeof LocalizationRoute
  '/plans': typeof PlansRoute
  '/settings': typeof SettingsRoute
  '/back-in-stock/settings': typeof BackInStockSettingsRoute
  '/backorders/settings': typeof BackordersSettingsRoute
  '/preorders/create': typeof PreordersCreateRoute
  '/back-in-stock': typeof BackInStockIndexRoute
  '/backorders': typeof BackordersIndexRoute
  '/preorders': typeof PreordersIndexRoute
  '/preorders/$uuid/display': typeof PreordersUuidDisplayRoute
  '/preorders/$uuid/report': typeof PreordersUuidReportRoute
  '/preorders/$uuid/settings': typeof PreordersUuidSettingsRoute
  '/preorders/$uuid/units-sold': typeof PreordersUuidUnitsSoldRoute
  '/preorders/$uuid': typeof PreordersUuidIndexRoute
  '/back-in-stock/products/$productId/variants': typeof BackInStockProductsProductIdVariantsRoute
  '/back-in-stock/products/$productId': typeof BackInStockProductsProductIdIndexRoute
  '/back-in-stock/products/$productId/contacts/$contactId': typeof BackInStockProductsProductIdContactsContactIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/': typeof IndexRoute
  '/display': typeof DisplayRoute
  '/emails': typeof EmailsRoute
  '/localization': typeof LocalizationRoute
  '/plans': typeof PlansRoute
  '/settings': typeof SettingsRoute
  '/back-in-stock/settings': typeof BackInStockSettingsRoute
  '/backorders/settings': typeof BackordersSettingsRoute
  '/preorders/create': typeof PreordersCreateRoute
  '/back-in-stock/': typeof BackInStockIndexRoute
  '/backorders/': typeof BackordersIndexRoute
  '/preorders/': typeof PreordersIndexRoute
  '/preorders/$uuid/display': typeof PreordersUuidDisplayRoute
  '/preorders/$uuid/report': typeof PreordersUuidReportRoute
  '/preorders/$uuid/settings': typeof PreordersUuidSettingsRoute
  '/preorders/$uuid/units-sold': typeof PreordersUuidUnitsSoldRoute
  '/preorders/$uuid/': typeof PreordersUuidIndexRoute
  '/back-in-stock/products/$productId/variants': typeof BackInStockProductsProductIdVariantsRoute
  '/back-in-stock/products/$productId/': typeof BackInStockProductsProductIdIndexRoute
  '/back-in-stock/products/$productId/contacts/$contactId': typeof BackInStockProductsProductIdContactsContactIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | '/'
    | '/display'
    | '/emails'
    | '/localization'
    | '/plans'
    | '/settings'
    | '/back-in-stock/settings'
    | '/backorders/settings'
    | '/preorders/create'
    | '/back-in-stock'
    | '/backorders'
    | '/preorders'
    | '/preorders/$uuid/display'
    | '/preorders/$uuid/report'
    | '/preorders/$uuid/settings'
    | '/preorders/$uuid/units-sold'
    | '/preorders/$uuid'
    | '/back-in-stock/products/$productId/variants'
    | '/back-in-stock/products/$productId'
    | '/back-in-stock/products/$productId/contacts/$contactId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | '/'
    | '/display'
    | '/emails'
    | '/localization'
    | '/plans'
    | '/settings'
    | '/back-in-stock/settings'
    | '/backorders/settings'
    | '/preorders/create'
    | '/back-in-stock'
    | '/backorders'
    | '/preorders'
    | '/preorders/$uuid/display'
    | '/preorders/$uuid/report'
    | '/preorders/$uuid/settings'
    | '/preorders/$uuid/units-sold'
    | '/preorders/$uuid'
    | '/back-in-stock/products/$productId/variants'
    | '/back-in-stock/products/$productId'
    | '/back-in-stock/products/$productId/contacts/$contactId'
  id:
    | '__root__'
    | '/'
    | '/display'
    | '/emails'
    | '/localization'
    | '/plans'
    | '/settings'
    | '/back-in-stock/settings'
    | '/backorders/settings'
    | '/preorders/create'
    | '/back-in-stock/'
    | '/backorders/'
    | '/preorders/'
    | '/preorders/$uuid/display'
    | '/preorders/$uuid/report'
    | '/preorders/$uuid/settings'
    | '/preorders/$uuid/units-sold'
    | '/preorders/$uuid/'
    | '/back-in-stock/products/$productId/variants'
    | '/back-in-stock/products/$productId/'
    | '/back-in-stock/products/$productId/contacts/$contactId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  IndexRoute: typeof IndexRoute
  DisplayRoute: typeof DisplayRoute
  EmailsRoute: typeof EmailsRoute
  LocalizationRoute: typeof LocalizationRoute
  PlansRoute: typeof PlansRoute
  SettingsRoute: typeof SettingsRoute
  BackInStockSettingsRoute: typeof BackInStockSettingsRoute
  BackordersSettingsRoute: typeof BackordersSettingsRoute
  PreordersCreateRoute: typeof PreordersCreateRoute
  BackInStockIndexRoute: typeof BackInStockIndexRoute
  BackordersIndexRoute: typeof BackordersIndexRoute
  PreordersIndexRoute: typeof PreordersIndexRoute
  PreordersUuidDisplayRoute: typeof PreordersUuidDisplayRoute
  PreordersUuidReportRoute: typeof PreordersUuidReportRoute
  PreordersUuidSettingsRoute: typeof PreordersUuidSettingsRoute
  PreordersUuidUnitsSoldRoute: typeof PreordersUuidUnitsSoldRoute
  PreordersUuidIndexRoute: typeof PreordersUuidIndexRoute
  BackInStockProductsProductIdVariantsRoute: typeof BackInStockProductsProductIdVariantsRoute
  BackInStockProductsProductIdIndexRoute: typeof BackInStockProductsProductIdIndexRoute
  BackInStockProductsProductIdContactsContactIdRoute: typeof BackInStockProductsProductIdContactsContactIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  IndexRoute: IndexRoute,
  DisplayRoute: DisplayRoute,
  EmailsRoute: EmailsRoute,
  LocalizationRoute: LocalizationRoute,
  PlansRoute: PlansRoute,
  SettingsRoute: SettingsRoute,
  BackInStockSettingsRoute: BackInStockSettingsRoute,
  BackordersSettingsRoute: BackordersSettingsRoute,
  PreordersCreateRoute: PreordersCreateRoute,
  BackInStockIndexRoute: BackInStockIndexRoute,
  BackordersIndexRoute: BackordersIndexRoute,
  PreordersIndexRoute: PreordersIndexRoute,
  PreordersUuidDisplayRoute: PreordersUuidDisplayRoute,
  PreordersUuidReportRoute: PreordersUuidReportRoute,
  PreordersUuidSettingsRoute: PreordersUuidSettingsRoute,
  PreordersUuidUnitsSoldRoute: PreordersUuidUnitsSoldRoute,
  PreordersUuidIndexRoute: PreordersUuidIndexRoute,
  BackInStockProductsProductIdVariantsRoute: BackInStockProductsProductIdVariantsRoute,
  BackInStockProductsProductIdIndexRoute: BackInStockProductsProductIdIndexRoute,
  BackInStockProductsProductIdContactsContactIdRoute: BackInStockProductsProductIdContactsContactIdRoute,
}

export const routeTree = rootRoute._addFileChildren(rootRouteChildren)._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/",
        "/display",
        "/emails",
        "/localization",
        "/plans",
        "/settings",
        "/back-in-stock/settings",
        "/backorders/settings",
        "/preorders/create",
        "/back-in-stock/",
        "/backorders/",
        "/preorders/",
        "/preorders/$uuid/display",
        "/preorders/$uuid/report",
        "/preorders/$uuid/settings",
        "/preorders/$uuid/units-sold",
        "/preorders/$uuid/",
        "/back-in-stock/products/$productId/variants",
        "/back-in-stock/products/$productId/",
        "/back-in-stock/products/$productId/contacts/$contactId"
      ]
    },
    "/": {
      "filePath": "index.tsx"
    },
    "/display": {
      "filePath": "display.tsx"
    },
    "/emails": {
      "filePath": "emails.tsx"
    },
    "/localization": {
      "filePath": "localization.tsx"
    },
    "/plans": {
      "filePath": "plans.tsx"
    },
    "/settings": {
      "filePath": "settings.tsx"
    },
    "/back-in-stock/settings": {
      "filePath": "back-in-stock.settings.tsx"
    },
    "/backorders/settings": {
      "filePath": "backorders.settings.tsx"
    },
    "/preorders/create": {
      "filePath": "preorders.create.tsx"
    },
    "/back-in-stock/": {
      "filePath": "back-in-stock.index.tsx"
    },
    "/backorders/": {
      "filePath": "backorders.index.tsx"
    },
    "/preorders/": {
      "filePath": "preorders.index.tsx"
    },
    "/preorders/$uuid/display": {
      "filePath": "preorders.$uuid.display.tsx"
    },
    "/preorders/$uuid/report": {
      "filePath": "preorders.$uuid.report.tsx"
    },
    "/preorders/$uuid/settings": {
      "filePath": "preorders.$uuid.settings.tsx"
    },
    "/preorders/$uuid/units-sold": {
      "filePath": "preorders.$uuid.units-sold.tsx"
    },
    "/preorders/$uuid/": {
      "filePath": "preorders.$uuid.index.tsx"
    },
    "/back-in-stock/products/$productId/variants": {
      "filePath": "back-in-stock.products.$productId.variants.tsx"
    },
    "/back-in-stock/products/$productId/": {
      "filePath": "back-in-stock.products.$productId.index.tsx"
    },
    "/back-in-stock/products/$productId/contacts/$contactId": {
      "filePath": "back-in-stock.products.$productId.contacts.$contactId.tsx"
    }
  }
}
ROUTE_MANIFEST_END */
