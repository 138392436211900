import { BlockStack, Card, Layout, SkeletonBodyText, SkeletonDisplayText, SkeletonPage } from '@shopify/polaris'
import i18next from 'i18next'

const CampaignSettingsFallback = () => {
  return (
    <SkeletonPage title={i18next.t('genericLoading')} backAction>
      <Layout>
        <Layout.AnnotatedSection title={i18next.t('campaignsDetailsTitle')} description={i18next.t('campaignsDetailsDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignsAvailabilityTitle')} description={i18next.t('campaignsAvailabilityDescription')}>
          <BlockStack gap="400">
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
            <Card>
              <BlockStack gap="400">
                <SkeletonDisplayText size="small" />
                <SkeletonBodyText lines={3} />
              </BlockStack>
            </Card>
          </BlockStack>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignDurationTitle')} description={i18next.t('campaignDurationDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignProductsTitle')} description={i18next.t('campaignProductsDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignPurchaseOptionsTitle')} description={i18next.t('campaignPurchaseOptionsDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
        <Layout.AnnotatedSection title={i18next.t('campaignFulfillmentTitle')} description={i18next.t('campaignFulfillmentDescription')}>
          <Card>
            <BlockStack gap="400">
              <SkeletonDisplayText size="small" />
              <SkeletonBodyText lines={3} />
            </BlockStack>
          </Card>
        </Layout.AnnotatedSection>
      </Layout>
    </SkeletonPage>
  )
}

export { CampaignSettingsFallback }
