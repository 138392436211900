import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import i18next from 'i18next'

import { bisListContactQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { Banner, Box, Card, EmptyState, Layout, SkeletonBodyText, SkeletonPage, Text } from '@shopify/polaris'
import { SkeletonTable } from '@/components/shared/SkeletonTable'
import { useSuspenseQuery } from '@tanstack/react-query'
import { BackInStockEmailEventsTable } from '@/components/backInStock/BackInStockEmailEventsTable'
import { EmailPreview } from '@/components/shared/EmailPreview'
import { SectionTitle } from '@/components/shared/SectionTitle'
import { ContactBisStatusBadge } from '@/components/shared/ContactBisStatusBadge'
import type { MenuActionDescriptor } from '@shopify/polaris/build/ts/src/types'
import { useMutateRemoveContact } from '@/hooks/useMutateRemoveContact'
import customersEmptyState from '@/illustrations/customers-empty-state.svg'
import { formatPrice } from '@/common/functions'

export const Route = createFileRoute('/back-in-stock/products/$productId/contacts/$contactId')({
  loader: async ({ params: { contactId }, context: { queryClient }, route: { path } }) => {
    try {
      await Promise.all([queryClient.ensureQueryData(settingsQueryOptions()), queryClient.ensureQueryData(bisListContactQueryOptions({ id: contactId }))])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const navigate = useNavigate()
  const { productId, contactId } = useParams({ strict: false }) as { productId: string; contactId: string }

  const { data } = useSuspenseQuery(bisListContactQueryOptions({ id: contactId }))
  const removeContact = useMutateRemoveContact()

  const customerName = data.customer?.firstName ? `${data.customer.firstName} ${data.customer?.lastName}` : data.customer?.email
  const isEmailSent = !!data.email_send
  const isOrderPlaced = !!data.bis
  const shopifyOrderUrl = isOrderPlaced ? `shopify://admin/orders/${data.bis.orderId.split('/').pop()}` : ''
  const shopifyCustomerUrl = `shopify://admin/customers/${data.customer.id.split('/').pop()}`

  let primaryActions = undefined
  const secondaryActions: MenuActionDescriptor[] | React.ReactNode = [
    {
      content: i18next.t('backInStockEditCustomerCta'),
      disabled: !data?.customer,
      url: shopifyCustomerUrl,
      target: '_blank',
    },
  ]

  if (!isEmailSent && !isOrderPlaced) {
    secondaryActions.push({
      content: i18next.t('backInStockContactDeleteCta'),
      destructive: true,
      disabled: removeContact.isPending,
      loading: removeContact.isPending,
      onAction: async () => {
        await removeContact.mutateAsync({ id: contactId })
        navigate({ to: `/back-in-stock/products/${productId}` })
      },
    })
  }

  if (shopifyOrderUrl) {
    primaryActions = {
      content: i18next.t('backInStockContactConvertedCta'),
      url: shopifyOrderUrl,
    }
  }

  return (
    <PageFrame
      title={customerName}
      titleMetadata={<ContactBisStatusBadge status={data.email_send?.status ?? null} />}
      backAction={{
        content: i18next.t('backInStockDashTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({
            to: '/back-in-stock/products/$productId',
            params: { productId },
          })
        },
      }}
      primaryAction={primaryActions}
      secondaryActions={secondaryActions}
    >
      {isOrderPlaced && (
        <Layout.Section>
          <Banner
            tone="success"
            title={i18next.t('backInStockContactConvertedTitle', { name: customerName })}
            action={
              shopifyOrderUrl
                ? {
                    content: i18next.t('backInStockContactConvertedCta'),
                    url: shopifyOrderUrl,
                  }
                : undefined
            }
          >
            <p>
              {data.bis?.amount
                ? i18next.t('backInStockContactConvertedDescriptionWithAmount', {
                    amount: formatPrice(data.bis.amount ?? 0, data.bis.currency ?? 'USD'),
                  })
                : i18next.t('backInStockContactConvertedDescription')}
            </p>
          </Banner>
        </Layout.Section>
      )}
      {isEmailSent ? (
        <>
          <Layout.Section variant="oneHalf">
            <SectionTitle title={i18next.t('backInStockEmailEventsTableTitle')} description={i18next.t('backInStockEmailEventsTableSubtitle')} />
            <BackInStockEmailEventsTable contactId={contactId} />
          </Layout.Section>
          <Layout.Section variant="oneHalf">
            <SectionTitle title={i18next.t('backInStockEmailPreviewTitle')} description={i18next.t('backInStockEmailPreviewSubtitle')} />
            <Card>
              <EmailPreview
                subject={data.email_send?.apiData?.Subject ?? ''}
                htmlContent={data.email_send?.apiData?.HtmlBody ?? ''}
                from={data.email_send?.apiData?.From ?? undefined}
                to={data.email_send?.apiData?.To?.length ? data.email_send?.apiData?.To[0] : undefined}
              />
            </Card>
          </Layout.Section>
        </>
      ) : (
        <Layout.Section>
          <Card padding="0">
            <EmptyState
              heading={i18next.t('backInStockContactNoEmailTitle')}
              image={customersEmptyState}
              action={{
                content: i18next.t('backInStockContactNoEmailCta'),
                onAction: async () => {
                  /* @ts-ignore */
                  await shopify.saveBar.leaveConfirmation()

                  navigate({ to: '/back-in-stock/products/$productId', params: { productId } })
                },
              }}
            >
              <Text as="p">{i18next.t('backInStockContactNoEmailDescription')}</Text>
            </EmptyState>
          </Card>
        </Layout.Section>
      )}
    </PageFrame>
  )
}

function Pending() {
  return (
    <SkeletonPage title={i18next.t('genericLoading')} primaryAction backAction>
      <Layout>
        <Layout.Section variant="oneHalf">
          <SectionTitle title={i18next.t('backInStockEmailEventsTableTitle')} description={i18next.t('backInStockEmailEventsTableSubtitle')} />
          <SkeletonTable rows={3} narrow={true} />
        </Layout.Section>
        <Layout.Section variant="oneHalf">
          <SectionTitle title={i18next.t('backInStockEmailPreviewTitle')} description={i18next.t('backInStockEmailPreviewSubtitle')} />
          <Card>
            <Box borderColor="border-secondary" borderWidth="025">
              <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
                <SkeletonBodyText lines={3} />
              </Box>
              <Box paddingBlock="300" paddingInline="400" borderBlockEndWidth="025" borderColor="border-secondary">
                <SkeletonBodyText lines={1} />
              </Box>
              <Box padding="400">
                <Box minHeight="400px" background="bg-fill-tertiary" />
              </Box>
            </Box>
          </Card>
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}