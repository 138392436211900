import { createFileRoute } from '@tanstack/react-router'

import { PageFrame } from '@/components/shared/PageFrame'
import { useTranslation } from 'react-i18next'

import { localisationQueryOptions } from '@/common/queryOptions'
import { LocalizationFallback } from '@/components/localization/LocalizationFallback'
import { LocalizationForm } from '@/components/localization/LocalizationForm'

export const Route = createFileRoute('/localization')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      await queryClient.ensureQueryData(localisationQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => {
    return <LocalizationFallback />
  },
  component: Index,
})

function Index() {
  const { t } = useTranslation()

  return (
    <PageFrame title={t('localisationTitle')} subtitle={t('localisationSubtitle')}>
      <LocalizationForm />
    </PageFrame>
  )
}
