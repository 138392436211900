import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import i18next from 'i18next'

import { settingsQueryOptions } from '@/common/queryOptions'
import { CampaignSettingsFallback } from '@/components/preorders/CampaignSettingsFallback'
import { CampaignForm } from '@/components/preorders/CampaignForm'

export const Route = createFileRoute('/preorders/create')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      await queryClient.ensureQueryData(settingsQueryOptions())
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => {
    return <CampaignSettingsFallback />
  },
  component: Index,
})

function Index() {
  const navigate = useNavigate()

  return (
    <PageFrame
      title={i18next.t('campaignsCreateTitle')}
      backAction={{
        content: i18next.t('campaignsManageTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: '/preorders' })
        },
      }}
    >
      <CampaignForm />
    </PageFrame>
  )
}
