import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import i18next from 'i18next'

import { backordersQueryOptions, reportsBackordersQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { BackordersFallback } from '@/components/backorders/BackordersFallback'
import { useSuspenseQuery } from '@tanstack/react-query'
import { useMutateSettings } from '@/hooks/useMutateSettings'
import { useEffect, useState } from 'react'
import { BackorderStatusBadge } from '@/components/shared/BackorderStatusBadge'
import { Banner, Button, Layout } from '@shopify/polaris'
import { BackorderPublishingQueue } from '@/components/backorders/BackorderPublishingQueue'
import { BackorderChart } from '@/components/backorders/BackorderChart'
import { BackorderTable } from '@/components/backorders/BackorderTable'
import { AppEmbedBlock } from '@/components/dashboard/AppEmbedBlock'
import { DateRangeProvider, useDateRange } from '@/hooks/useDateRange'
import { DateRangePicker } from '@/components/shared/DateRangePicker'

export const Route = createFileRoute('/backorders/')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      await Promise.all([
        queryClient.ensureQueryData(settingsQueryOptions()),
        queryClient.ensureQueryData(
          reportsBackordersQueryOptions({
            from: undefined,
            to: undefined,
          })
        ),
        queryClient.ensureQueryData(
          backordersQueryOptions({
            financialStatus: undefined,
            fulfillmentStatus: undefined,
            page: 1,
          })
        ),
      ])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => {
    return <BackordersFallback />
  },
  component: () => {
    return (
      <DateRangeProvider>
        <Page />
      </DateRangeProvider>
    )
  },
})

function Page() {
  const navigate = useNavigate()
  const { dateRange, setDateRange } = useDateRange()

  const [backorderQueryRefetchInterval, setBackorderQueryRefetchInterval] = useState<number | false>(false)

  const { data: settings } = useSuspenseQuery({ ...settingsQueryOptions(), refetchInterval: backorderQueryRefetchInterval, refetchIntervalInBackground: true })

  // Mutation to modify the campaign schedule
  const settingsMutation = useMutateSettings()

  // Change the refetch interval based on the campaign status
  useEffect(() => {
    const fastRefetchStatuses = [
      'ANALYZING',
      'ANALYZING_MIXED',
      'DISCOVERING',
      'UPLOADING',
      'UPDATING_PRODUCTS_ON',
      'UPDATING_PRODUCTS_OFF',
      'UPDATING_PRODUCTS_MIXED',
    ]

    if (fastRefetchStatuses.includes(settings.backorderStatus)) {
      setBackorderQueryRefetchInterval(3000)
    } else {
      setBackorderQueryRefetchInterval(false)
    }
  }, [settings.backorderStatus])

  // Local state to immediately hide banner on dismiss
  const [isInventoryWarningLocallyDismissed, setIsInventoryWarningLocallyDismissed] = useState(false)

  const onDismiss = (dismissFlags: typeof settings.dismissFlags) => {
    settingsMutation.mutateAsync({ dismissFlags })
  }

  return (
    <PageFrame
      title={i18next.t('backordersDashTitle')}
      subtitle={i18next.t('backordersDashTitleSubtitle')}
      titleMetadata={<BackorderStatusBadge backorderEnabled={!!settings.backorderEnabled} status={settings.backorderStatus} withProgress={false} />}
      primaryAction={
        <Button
          variant="primary"
          disabled={settings.backorderStatus !== 'COMPLETE' && settings.backorderStatus !== 'ERROR'}
          onClick={() => {
            settingsMutation.mutate({ backorderEnabled: !settings.backorderEnabled })
          }}
          loading={settingsMutation.isPending}
        >
          {settings.backorderEnabled ? i18next.t('backordersCtaUnpublish') : i18next.t('backordersCtaPublish')}
        </Button>
      }
      secondaryActions={[
        {
          content: i18next.t('genericConfigure'),
          disabled: settings.backorderStatus !== 'COMPLETE' && settings.backorderStatus !== 'ERROR',
          onAction: () => {
            navigate({ to: '/backorders/settings' })
          },
        },
      ]}
    >
      {settings.backorderEnabled && <AppEmbedBlock />}
      {settings.backorderStatus === 'ERROR' ? (
        <Layout.Section>
          <Banner tone="critical" title={i18next.t('backordersChecklistErrorTitle')}>
            <p>{i18next.t('backordersChecklistErrorDescription')}</p>
          </Banner>
        </Layout.Section>
      ) : (
        <BackorderPublishingQueue />
      )}

      {!isInventoryWarningLocallyDismissed &&
        !settings.dismissFlags?.backorderInventoryWarning &&
        settings.backorderEnabled &&
        !settings.backorderIsContinueSellingManaged && (
          <Layout.Section>
            <Banner
              title={i18next.t('backordersInventoryPolicyWarningTitle')}
              tone="warning"
              action={{
                content: i18next.t('backordersInventoryPolicyWarningCta'),
                onAction: () => {
                  navigate({ to: '/backorders/settings' })
                },
              }}
              onDismiss={() => {
                onDismiss({ ...settings.dismissFlags, backorderInventoryWarning: true })
                setIsInventoryWarningLocallyDismissed(true)
              }}
            >
              <p>{i18next.t('backordersInventoryPolicyWarningDescription')}</p>
            </Banner>
          </Layout.Section>
        )}

      <Layout.Section>
        <DateRangePicker value={dateRange} onDateRangeSelect={setDateRange} />
      </Layout.Section>

      <Layout.Section>
        <BackorderChart />
      </Layout.Section>

      <Layout.Section>
        <BackorderTable />
      </Layout.Section>
    </PageFrame>
  )
}
