import { buildAPIUrl } from '@/common/functions'
import { useMutation } from '@tanstack/react-query'

export const useMutateInstall = () => {
  return useMutation({
    mutationFn: async () => {
      const token = await shopify.idToken()

      const searchParams = new URLSearchParams()
      searchParams.set('host', shopify.config?.host ?? '')
      searchParams.set('token', token)
      searchParams.set('redirect', 'false')

      const requestURL = buildAPIUrl('auth/install', searchParams)

      return fetch(requestURL, {
        method: 'POST',
      })
        .then((res) => {
          if (res.status === 403) {
            throw 'Access denied'
          }
          return res.json()
        })
        .then((res) => res.message)
    },
  })
}
