import { SkeletonChart } from '@/components/shared/SkeletonChart'
import { SkeletonTable } from '@/components/shared/SkeletonTable'
import { Layout, SkeletonPage } from '@shopify/polaris'
import i18next from 'i18next'

const CampaignFallback = () => {
  return (
    <SkeletonPage title={i18next.t('genericLoading')} primaryAction backAction>
      <Layout>
        <Layout.Section>
          <SkeletonChart />
        </Layout.Section>
        <Layout.Section>
          <SkeletonTable />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}

export { CampaignFallback }
