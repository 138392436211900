import { createFileRoute, useNavigate, useParams } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import i18next from 'i18next'

import { reportsBisVariantsQueryOptions, shopifyProductQueryOptions, shopifyProductVariantsQueryOptions } from '@/common/queryOptions'
import { Layout, SkeletonPage } from '@shopify/polaris'
import { BackInStockProductVariantsTable } from '@/components/backInStock/BackInStockProductVariantsTable'
import { SkeletonTable } from '@/components/shared/SkeletonTable'
import { useSuspenseQuery } from '@tanstack/react-query'

export const Route = createFileRoute('/back-in-stock/products/$productId/variants')({
  loader: async ({ params: { productId }, context: { queryClient }, route: { path } }) => {
    try {
      const bisProductVariantsPromise = await queryClient.ensureQueryData(reportsBisVariantsQueryOptions({ id: productId }))
      const variantGids = bisProductVariantsPromise?.data?.flatMap((product) => (product.variantId ? [product.variantId] : [])) ?? []

      await queryClient.ensureQueryData(shopifyProductVariantsQueryOptions({ variantGids }))
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: Pending,
  component: Page,
})

function Page() {
  const navigate = useNavigate()
  const { productId } = useParams({ strict: false }) as { productId: string }

  const {
    data: { product: shopifyProduct },
  } = useSuspenseQuery(shopifyProductQueryOptions({ productId: `gid://shopify/Product/${productId}` }))

  return (
    <PageFrame
      title={shopifyProduct.title}
      subtitle={i18next.t('reportBackInStockVariantsSubtitle')}
      backAction={{
        content: i18next.t('backInStockDashTitle'),
        onAction: async () => {
          /* @ts-ignore */
          await shopify.saveBar.leaveConfirmation()

          navigate({ to: '/back-in-stock/products/$productId', params: { productId } })
        },
      }}
    >
      <Layout.Section>
        <BackInStockProductVariantsTable productId={productId} />
      </Layout.Section>
    </PageFrame>
  )
}

function Pending() {
  return (
    <SkeletonPage title={i18next.t('genericLoading')} backAction>
      <Layout>
        <Layout.Section>
          <SkeletonTable rows={5} />
        </Layout.Section>
      </Layout>
    </SkeletonPage>
  )
}
