import { buildAPIUrl } from '@/common/functions'
import { dnsQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import type { FetchSettingsResponse, MutateSettingsPayload } from '@/common/types'
import { useMutation, useQueryClient } from '@tanstack/react-query'

export const useMutateSettings = () => {
  const queryClient = useQueryClient()

  const requestURL = buildAPIUrl('settings')

  return useMutation<FetchSettingsResponse, unknown, Partial<MutateSettingsPayload>>({
    mutationFn: (data: Partial<MutateSettingsPayload> = {}) =>
      fetch(requestURL, {
        body: JSON.stringify(data),
        method: 'POST',
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json()
          }
          throw 'Error updating settings'
        })
        .then((res) => res.message),
    onSettled() {
      queryClient.invalidateQueries({
        queryKey: settingsQueryOptions().queryKey,
      })

      queryClient.invalidateQueries({
        queryKey: dnsQueryOptions().queryKey,
      })
    },
  })
}
