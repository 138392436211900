import { useMutateSettings } from '@/hooks/useMutateSettings'
import { BlockStack, Card, Button, Collapsible, Box, InlineStack, Layout, Text, ProgressBar, SkeletonDisplayText, SkeletonBodyText } from '@shopify/polaris'
import i18next from 'i18next'
import { useSuspenseQueries } from '@tanstack/react-query'

/* https://undraw.co/search */
import InvestingIllustration from '@/illustrations/finance.svg?react'
import PreorderIllustration from '@/illustrations/preorder.svg?react'
import BackInStockIllustration from '@/illustrations/back-in-stock.svg?react'
import { campaignQueryOptions, settingsCheckAppEmbedQueryOptions, settingsQueryOptions } from '@/common/queryOptions'
import { useNavigate } from '@tanstack/react-router'
import { useEffect, useMemo, useState } from 'react'
import { ChevronDownIcon, ChevronUpIcon, XIcon } from '@shopify/polaris-icons'
import { ChecklistItem, type ChecklistItemPropsWithOpen } from '@/components/shared/Checklist'
import type { MutateSettingsResponse } from '@/common/types'

type OnboardingItem = ChecklistItemPropsWithOpen & {}

// Deep link to add the embed block in the theme customizer
const appEmbedLink = `https://admin.shopify.com/store/${shopify?.config?.shop ? shopify.config.shop.split('.')[0] : ''}/themes/current/editor?context=apps&activateAppId=${
  import.meta.env.VITE_APP_EMBED_ID
}/app-embed`

const OnboardingFallback = () => {
  return (
    <Layout.Section>
      <BlockStack gap="300">
        <Card padding="400">
          <BlockStack gap="300">
            <SkeletonDisplayText size="large" />
            <SkeletonBodyText lines={2} />
            <SkeletonDisplayText size="large" />
            <SkeletonBodyText lines={2} />
            <SkeletonDisplayText size="large" />
            <SkeletonBodyText lines={2} />
          </BlockStack>
        </Card>
      </BlockStack>
    </Layout.Section>
  )
}

const Onboarding = () => {
  const navigate = useNavigate()

  const [isFinishedInit, setIsFinishedInit] = useState(false)
  const [isDismissed, setIsDismissed] = useState(false)
  const [isExpanded, setIsExpanded] = useState(true)
  const [isPolling, setIsPolling] = useState(false)
  const [onboardingItems, setOnboardingItems] = useState<OnboardingItem[] | null>(null)
  const [pendingId, setPendingId] = useState<string | null>(null)

  const [
    {
      data: { data: campaigns },
    },
    { data: settings },
    { data: hasEmbedBlock },
  ] = useSuspenseQueries({
    queries: [
      campaignQueryOptions(),
      settingsQueryOptions(),
      {
        ...settingsCheckAppEmbedQueryOptions(),
        refetchInterval: isPolling ? 3000 : false,
        refetchIntervalInBackground: true,
      },
    ],
  })

  useEffect(() => {
    if (hasEmbedBlock) {
      setIsPolling(false)
    }
  }, [hasEmbedBlock])

  const dismissFlags = useMemo(() => {
    return settings?.dismissFlags
  }, [settings?.dismissFlags])

  const settingsMutation = useMutateSettings()

  const onDismiss = (dismissFlags: typeof settings.dismissFlags) => {
    settingsMutation.mutate({ dismissFlags })
  }

  const dismissOnboarding = () => {
    setIsDismissed(true)
    settingsMutation.mutate(
      { onboarded: true },
      {
        onSuccess: (data: MutateSettingsResponse) => {
          shopify.toast.show(i18next.t('onboardingHiddenToast'), {
            duration: 10000,
            action: i18next.t('genericUndo'),
            onAction: () => {
              setIsDismissed(false)
              settingsMutation.mutate({ onboarded: false })
              shopify.toast.hide(i18next.t('onboardingHiddenToast'))
            },
          })
          return data
        },
      }
    )
  }

  // Marked as done or not
  const appEmbedIncomplete = !hasEmbedBlock
  const preorderIncomplete = dismissFlags?.createCampaign === false || (dismissFlags?.createCampaign === undefined && (!campaigns || !campaigns.length))
  const backorderIncomplete = dismissFlags?.activateBackorders === false || (dismissFlags?.activateBackorders === undefined && !settings?.backorderEnabled)
  const bisIncomplete = dismissFlags?.activateBis === false || (dismissFlags?.activateBis === undefined && !settings?.bisEnabled)

  const completeCount = useMemo(() => (!onboardingItems ? 0 : onboardingItems.filter((i) => i.status === 'COMPLETE').length), [onboardingItems])
  const totalCount = useMemo(() => (!onboardingItems ? 0 : onboardingItems.length), [onboardingItems])

  // Collapse if complete
  useEffect(() => {
    if (totalCount > 0 && completeCount === totalCount) {
      setIsExpanded(false)
    }
  }, [completeCount, totalCount])

  useEffect(() => {
    const items: OnboardingItem[] = [
      {
        id: 'activateAppEmbed',
        title: i18next.t('embedBlockBannerTitle'),
        description: i18next.t('embedBlockBannerDescription'),
        status: isPolling ? 'IN_PROGRESS' : appEmbedIncomplete ? 'INCOMPLETE' : 'COMPLETE',
        isDefaultOpen: false,
        primaryButton: {
          content: i18next.t('embedBlockBannerAction'),
          props: {
            onClick: () => {
              setIsPolling(true)
              open(appEmbedLink, '_blank')
            },
          },
        },
        secondaryButton: {
          content: i18next.t('genericLearnMore'),
          props: {
            url: 'https://docs.shopside.com.au/article/43-getting-started-with-early-bird-shopify-pre-order-app',
            external: true,
          },
        },
      },
      {
        id: 'createCampaign',
        title: i18next.t('thingsToDoCreateCampaignTitle'),
        description: i18next.t('thingsToDoCreateCampaignDescription'),
        illustration: <PreorderIllustration />,
        status: preorderIncomplete ? 'INCOMPLETE' : 'COMPLETE',
        isDefaultOpen: false,
        primaryButton: {
          content: i18next.t('thingsToDoCreateCampaignPrimaryCta'),
          props: {
            onClick: () => {
              navigate({ to: '/preorders/create' })
            },
          },
        },
        secondaryButton: {
          content: i18next.t('genericWatchVideo'),
          props: {
            url: 'https://youtu.be/DZ3S4aGBQs0?si=HsAjBVnFZSGE5fK5&t=55',
            external: true,
          },
        },
        iconAction: () => {
          setPendingId('createCampaign')
          onDismiss({ ...dismissFlags, createCampaign: preorderIncomplete })
        },
      },
      {
        id: 'activateBackorders',
        title: i18next.t('thingsToDoActivateBackordersTitle'),
        description: i18next.t('thingsToDoActivateBackordersDescription'),
        illustration: <InvestingIllustration />,
        status: backorderIncomplete ? 'INCOMPLETE' : 'COMPLETE',
        isDefaultOpen: false,
        primaryButton: {
          content: i18next.t('thingsToDoActivateBackordersPrimaryCta'),
          props: {
            onClick: () => {
              navigate({ to: '/backorders/settings' })
            },
          },
        },
        secondaryButton: {
          content: i18next.t('genericWatchVideo'),
          props: {
            url: 'https://youtu.be/3YaAzeruznA?si=fKa0QhzALDBIymPv&t=53',
            external: true,
          },
        },
        iconAction: () => {
          setPendingId('activateBackorders')
          onDismiss({ ...dismissFlags, activateBackorders: backorderIncomplete })
        },
      },
      {
        id: 'activateBackInStock',
        title: i18next.t('thingsToDoActivateBackInStockTitle'),
        description: i18next.t('thingsToDoActivateBackInStockDescription'),
        illustration: <BackInStockIllustration />,
        status: bisIncomplete ? 'INCOMPLETE' : 'COMPLETE',
        isDefaultOpen: false,
        primaryButton: {
          content: i18next.t('thingsToDoActivateBackInStockPrimaryCta'),
          props: {
            onClick: () => {
              navigate({ to: '/back-in-stock/settings' })
            },
          },
        },
        secondaryButton: {
          content: i18next.t('genericWatchVideo'),
          props: {
            url: 'https://youtu.be/HNthm9dSnXY?si=Nu9s7MWm7d1ZPLhX&t=55',
            external: true,
          },
        },
        iconAction: () => {
          setPendingId('activateBackInStock')
          onDismiss({ ...dismissFlags, activateBis: bisIncomplete })
        },
      },
    ]

    // Loop over onboarding items and set isDefaultOpen for first item
    let openedIndex = -1

    items.forEach((item, i) => {
      if (openedIndex < 0) {
        if (item.status !== 'COMPLETE') {
          item.isDefaultOpen = true
          openedIndex = i
        }
      }
    })

    if (openedIndex === -1) {
      setIsExpanded(false)
    }

    // Set onboarding items
    setOnboardingItems(items)
    setIsFinishedInit(true)
  }, [dismissFlags, isPolling])

  if (isDismissed || settings.onboarded || !onboardingItems || totalCount === 0) {
    if (isFinishedInit || settings?.onboarded !== false) {
      return null
    }

    return <OnboardingFallback />
  }

  return (
    <Layout.Section>
      <div className="onboardingCard">
        <Box position="relative">
          <Card padding="0">
            <Box position="absolute" zIndex="1" width={'100%'} padding="400">
              <div className="onboardingActions">
                <BlockStack align="center">
                  <InlineStack gap="400" blockAlign="stretch" align="end">
                    <Button variant="tertiary" icon={XIcon} onClick={() => dismissOnboarding()} />
                    <Button variant="tertiary" onClick={() => setIsExpanded(!isExpanded)} icon={isExpanded ? ChevronUpIcon : ChevronDownIcon} />
                  </InlineStack>
                </BlockStack>
              </div>
            </Box>
            <Box padding="400">
              <InlineStack gap="400" blockAlign="stretch" align="space-between">
                <BlockStack gap="200" align="start">
                  <InlineStack gap="200" blockAlign="stretch" align="space-between">
                    <Text as="h2" variant="headingMd">
                      <span>{i18next.t('onboardingTitle')}</span>
                    </Text>
                  </InlineStack>
                  <Text as="p">
                    <span>{i18next.t('onboardingDescription')}</span>
                  </Text>
                  <InlineStack gap="0" blockAlign="stretch" align="space-between">
                    <Box width="40%">
                      <Text as="p">
                        {i18next.t('onboardingProgressDescription', {
                          complete: completeCount,
                          total: totalCount,
                        })}
                      </Text>
                    </Box>
                    <Box width="60%" paddingBlockStart="200">
                      <ProgressBar
                        progress={(completeCount / totalCount) * 100}
                        size="small"
                        animated
                        tone={completeCount === totalCount ? 'success' : 'highlight'}
                      />
                    </Box>
                  </InlineStack>
                </BlockStack>
              </InlineStack>
            </Box>
            <Collapsible open={isExpanded} id="onboarding">
              <Box padding="200" paddingBlockStart="0">
                <BlockStack gap="050">
                  {onboardingItems?.length &&
                    onboardingItems.map((item) => (
                      <ChecklistItem
                        key={item.id}
                        id={item.id}
                        title={item.title}
                        description={item?.description}
                        illustration={item?.illustration}
                        status={settingsMutation.isPending && pendingId === item.id ? 'IN_PROGRESS' : item.status}
                        isDefaultOpen={item.isDefaultOpen}
                        primaryButton={item?.primaryButton}
                        secondaryButton={item?.secondaryButton}
                        onOpen={(id) => {
                          setOnboardingItems(
                            onboardingItems.map((i) => {
                              return {
                                ...i,
                                isDefaultOpen: id === i.id,
                              }
                            })
                          )
                        }}
                        iconAction={item.iconAction}
                      />
                    ))}
                </BlockStack>
              </Box>
            </Collapsible>
            {completeCount === totalCount && (
              <div className="onboardingFooter">
                <Button variant="secondary" onClick={() => dismissOnboarding()}>
                  {i18next.t('onboardingDismissGuide')}
                </Button>
              </div>
            )}
          </Card>
        </Box>
      </div>
    </Layout.Section>
  )
}

export { OnboardingFallback, Onboarding }
