import { createFileRoute, useNavigate } from '@tanstack/react-router'
import { PageFrame } from '@/components/shared/PageFrame'
import i18next from 'i18next'

import { campaignReportsOrdersQueryOptions, campaignQueryOptions, settingsQueryOptions } from '@/common/queryOptions'

import { BlockStack, Button, Layout } from '@shopify/polaris'
import { CampaignChart } from '@/components/preorders/CampaignChart'
import { CampaignTable } from '@/components/preorders/CampaignTable'
import { CampaignsFallback } from '@/components/preorders/CampaignsFallback'
import { DateRangeProvider } from '@/hooks/useDateRange'

export const Route = createFileRoute('/preorders/')({
  loader: async ({ context: { queryClient }, route: { path } }) => {
    try {
      await Promise.all([
        queryClient.ensureQueryData(settingsQueryOptions()),
        queryClient.ensureQueryData(
          campaignQueryOptions({
            status: undefined,
            page: 1,
          })
        ),
        queryClient.ensureQueryData(
          campaignReportsOrdersQueryOptions({
            uuid: undefined,
            from: undefined,
            to: undefined,
          })
        ),
      ])
    } catch (error) {
      console.error('Error in page loader.', path, error)
      throw error
    }
  },
  pendingComponent: () => {
    return <CampaignsFallback />
  },
  component: () => {
    return (
      <DateRangeProvider>
        <Index />
      </DateRangeProvider>
    )
  },
})

function Index() {
  const navigate = useNavigate()
  return (
    <PageFrame
      title={i18next.t('campaignsTitle')}
      subtitle={i18next.t('campaignsSubtitle')}
      primaryAction={
        <Button
          variant="primary"
          onClick={() => {
            navigate({ to: '/preorders/create' })
          }}
        >
          {i18next.t('campaignsCta')}
        </Button>
      }
    >
      <Layout.Section>
        <BlockStack gap="400">
          <CampaignChart />
          <CampaignTable />
        </BlockStack>
      </Layout.Section>
    </PageFrame>
  )
}
