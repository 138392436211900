import type { UseQueryResult } from '@tanstack/react-query'
import i18next from 'i18next'

import { formatTimestamp } from '@/common/datetime'

export const isValidHex = (color: string) => {
  if (!color || typeof color !== 'string') return false

  // Validate hex values
  //if (color.substring(0, 1) === '#') color = color.substring(1)

  return /^#([a-fA-Z0-9]{6}|[a-fA-Z0-9]{3})$/.test(color)
}

export const openBeacon = (screen: 'ANSWERS' | 'ASK') => {
  window.Beacon('open')

  if (screen === 'ASK') {
    window.Beacon('navigate', '/ask')
  }

  if (screen === 'ANSWERS') {
    window.Beacon('navigate', '/answers')
  }
}

// Takes a number and returns in $1,000 format
export const formatPrice = (price: number, currency = 'USD') => {
  return i18next.t('intlCurrency', {
    val: price,
    formatParams: {
      val: {
        style: 'currency',
        currency: currency,
        currencyDisplay: 'narrowSymbol',
        maximumFractionDigits: 2,
      },
    },
  })
}

export const formatNumber = (number: number, precision = 0) => {
  return i18next.t('intlNumber', { val: number, maximumFractionDigits: precision })
}

export const buildAdminUrl = (path?: string, isAppURL = true) => {
  const storeName = shopify?.config?.shop ? shopify.config.shop.split('.')[0] : ''

  let url = `https://admin.shopify.com/store/${storeName}`

  if (isAppURL) {
    url += `/apps/${import.meta.env.VITE_APP_APP_API_KEY}`
  }

  if (path) {
    url += `/${path}`
  }

  return url
}

export const buildAPIUrl = (path: string, searchParams?: URLSearchParams) => {
  const baseURL = import.meta.env.VITE_APP_API_URL
  const url = new URL(`${baseURL}/${path}`)

  let params = new URLSearchParams()

  if (!searchParams) {
    params = new URLSearchParams()
  } else {
    params = searchParams
  }

  params.set('shop', shopify?.config?.shop ?? '')
  url.search = params.toString()

  return url.toString()
}

interface ReplaceObject {
  find: string
  replace: string
}

export const replaceInTemplate = (template: string, replacements: ReplaceObject[]): string => {
  let result = template

  for (const replacement of replacements) {
    const { find, replace } = replacement

    if (replace) {
      result = result.replace(new RegExp(find, 'g'), replace)
    }
  }

  return result
}

/**
 * Check an array of quries and return true if any of them are loading
 */
export const isQueryLoading = (queries: UseQueryResult[]): boolean => {
  return queries.some((query) => query.isLoading)
}

export const chartTitleFormatter = (value: string | number | null) => {
  if (!value) return ''
  return formatTimestamp(value.toString(), 'D MMM')
}

export const chartTooltipTitleFormatter = (value: string | number | null) => {
  if (!value) return ''
  return formatTimestamp(value.toString(), 'D MMMM YYYY')
}

export const chartValueFormatter = (value: string | number | null, currency = 'USD', digits = 0) => {
  let parsedValue = value ?? 0
  if (typeof parsedValue !== 'number') {
    parsedValue = Number.parseFloat(parsedValue.replace(/[^\d.-]/g, '')) || 0
  }
  return new Intl.NumberFormat('en', {
    style: 'currency',
    currency: currency,
    currencyDisplay: 'narrowSymbol',
    maximumFractionDigits: digits,
  }).format(parsedValue)
}

// TODO: Make this stripGid and just loop when needed
export const stripGids = (gids: string[]): string[] => gids.map((id) => id.split('/').pop() || '')
export const stripGid = (gid: string): string | undefined => gid.split('/').pop()

export const getBasePlanName = (planName: string, removeFrequency = true): string => {
  let result = planName.replace(/\(Test\)/g, '').trim()

  if (removeFrequency) {
    result = result.replace(/\(Annual\)/g, '').trim()
  }

  return result
}
